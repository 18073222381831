import { CustomDomain } from '@/types';
import { ComponentType, useCallback } from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { useHistory } from 'react-router-dom';

export const CustomDomainCreate: ComponentType = (props) => {
  const history = useHistory();

  const onSuceess = useCallback(
    (result: { data: CustomDomain }) => {
      const url = `/custom-domains/${result.data.id}/show`;
      history.push(url);
    },
    [history],
  );

  return (
    <Create onSuccess={onSuceess} {...props}>
      <SimpleForm>
        <TextInput fullWidth source="organizationId" validate={[required()]} />
        <TextInput fullWidth source="domain" validate={[required()]} />
        <TextInput
          fullWidth
          source="defaultRedirectUrl"
          validate={[required()]}
          defaultValue="https://eventx.io/"
        />
      </SimpleForm>
    </Create>
  );
};
