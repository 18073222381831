export const eventRoutes = Object.freeze({
  eventPage: (
    params: {
      eventId: string;
    } = {
      eventId: ':eventId',
    },
  ) => `/events/${params.eventId}`,
});

export const constructFullEventUrl = (path: string) =>
  `${process.env.REACT_APP_EVENT_BASE_URL ?? ''}${path}`;
