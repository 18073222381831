import React, { FC, useCallback } from 'react';
import Editor from '@monaco-editor/react';
import { Box, Typography, styled } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import { CustomAutomatedEmailSenderType } from '@/types';

interface Props {
  source: string;
  label: string;
  initialValue?: CustomAutomatedEmailSenderType;
  helperText?: string;
}

const HintText = styled('div')({
  marginTop: 10,
  marginRight: 20,
  fontSize: 14,
  color: '#999',
});

export const JsonInput: FC<Props> = ({
  initialValue,
  source,
  label,
  helperText,
}) => {
  const { values } = useFormState();

  const value =
    initialValue ?? (values.source as CustomAutomatedEmailSenderType);
  const form = useForm();

  const formatJSON = (val: CustomAutomatedEmailSenderType) => {
    try {
      return JSON.stringify(val, null, 2);
    } catch {
      const errorJson = {
        error: {
          msg: 'Error',
          ...val,
        },
      };
      return JSON.stringify(errorJson, null, 2);
    }
  };

  const handleChange = useCallback(
    (jsonData: string | undefined): void => {
      form.change(source, JSON.parse(jsonData ?? 'null'));
    },
    [form, source],
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6">{label}</Typography>
      <Editor
        value={formatJSON(value)}
        language="json"
        width="500px"
        height="200px"
        theme="vs-dark"
        onChange={handleChange}
      />
      <HintText dangerouslySetInnerHTML={{ __html: helperText ?? '' }} />
    </Box>
  );
};
