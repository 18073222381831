import { Link } from 'react-router-dom';
import IconAdd from '@material-ui/icons/Add';
import { Button, ResourceComponentProps, useRecordContext } from 'react-admin';
import { CreditSystemBalance } from '@/types/credit-system';

export const CreditSystemBalanceEditButton = (
  props: ResourceComponentProps & {
    subscriptionId: string;
    title?: string;
  },
) => {
  const { title, subscriptionId, ...rest } = props;
  const record = useRecordContext<CreditSystemBalance>(props);
  const fullPath = `/subscriptions/${subscriptionId}/credit-system/${record.creditType}/balance`;

  return (
    <Button
      component={Link}
      to={fullPath}
      label={title ?? 'Edit'}
      title={title ?? 'Edit'}
      {...rest}
    >
      <IconAdd />
    </Button>
  );
};
