import { DeleteButtonProps, DeleteWithConfirmButton } from 'react-admin';

export const TeamMemberDeleteButton = (
  props: DeleteButtonProps & {
    resourceId: string;
    title?: string;
  },
) => {
  const { undoable, mutationMode, record, resourceId, title, ...rest } = props;
  if (!record || record.id === null) {
    return null;
  }

  return (
    <DeleteWithConfirmButton
      mutationMode="pessimistic"
      confirmTitle={title ?? 'Delete Manager'}
      record={{
        ...record,
        id: `${resourceId}/${record.id}`,
      }}
      undoable={undoable}
      {...rest}
    />
  );
};
