export const injectedScriptTypesLanguage = 'typescript';

export const injectedScriptTypesPath = 'ts:filename/types.d.ts';

// need to sync with esaas-web repo periodically
export const injectedScriptTypes = `type LocationLatLng = {
  lat: string;
  lng: string;
};
enum EventEntryType {
  BY_INVITE_ONLY = "by_invite_only",
  PUBLIC = "public",
}
enum MediaType {
  IMAGE = "image",
  VIDEO = "video",
  FILE = "file",
}
type MediaResponse = {
  id: string;
  originalFilename: string;
  previewFilename?: string;
  originalFilepath: string;
  previewFilepath?: string;
  mimeType: string;
  type: MediaType;
  cloudflarePlaybackUrl?: string;
};
enum AttendeeRole {
  ORGANIZER = "organizer",
  SPEAKER = "speaker",
  HOST = "host",
  EXHIBITOR = "exhibitor",
  BOOTH_REP = "booth-rep",
  VISITOR = "visitor",
}
type EventFeatureConfig = Record<string, boolean> & Record<string, unknown>;
type EventType =
  | "seminar_or_talk"
  | "course_training_or_workshop"
  | "concert_or_performance"
  | "festival_or_fair"
  | "meeting_or_networking_event"
  | "party_or_social_gathering"
  | "tradeshow_consumer_show_or_expo"
  | "other";
type BoothHall = {
  id: string;
  name: string;
  desktopBackgroundMedia?: MediaResponse | null;
  mobileBackgroundMedia?: MediaResponse | null;
  isBoothTierFilterEnabled?: boolean;
};
type AttendeeVisibilityControl = {
  shouldHideAttendeeCount?: boolean;
  shouldHideWltViewerCount?: boolean;
  shouldHidePeopleList?: boolean;
  shouldHidePublicChat?: boolean;
  shouldHideQnA?: boolean;
  shouldHidePolling?: boolean;
  shouldShowPrivateChatVideoCall?: boolean;
  shouldHideMeetup?: boolean;
  shouldHideBoothChat?: boolean;
};
enum DefaultNavItemType {
  LOBBY = 'lobby',
  HALL = 'hall',
  NETWORKING_LOUNGE = 'networking_lounge',
  SCHEDULE = 'schedule',
  SPEAKER = 'speaker',
  PRODUCT = 'product',
  SPONSOR = 'sponsor',
}
enum CustomNavItemType {
  CATEGORY = 'category',
  EXTERNAL_LINK = 'external_link',
  IFRAME = 'iframe',
  HTML = 'html',
}
enum NavItemOpenBehavior {
  NEW_TAB = 'new_tab',
  SAME_TAB = 'same_tab',
};
type NavItemResponse = {
  id: string;
  type: \`\${DefaultNavItemType}\` | \`\${CustomNavItemType}\`;
  config: {
    showOnPage: boolean;
    openBehavior?: NavItemOpenBehavior;
  };
  name?: string | null;
  href?: string | null;
  parentNavItemId: string | null;
  childNavItems?: NavItemResponse[];
  disabled?: boolean;
};
type GetSocialSharingSettingsResponse = {
  ogTitle: string;
  ogDescription: string;
  ogImage: MediaResponse | null;
};
enum EventFormatType {
  VIRTUAL = 'virtual',
  IN_PERSON = 'in-person',
  HYBRID = 'hybrid',
};
enum EmailReminderControl {
  ONE_HOUR = 'oneHour',
  ONE_DAY = 'oneDay',
};

enum ReleaseStage {
  DEV = 'dev',
  INTERNAL = 'internal',
  ALPHA = 'alpha',
  BETA = 'beta',
  PREVIEW = 'preview',
  PUBLIC = 'public',
}
type SignupIntent = 'attend' | 'host' | 'both' | 'exhibit' | 'speak';
type SignupMethod =
  | 'magic-link'
  | 'email'
  | 'phone'
  | SocialSignupMethod;
enum SignupSite {
  CN = 'cn',
}

type CustomField = {
  value: string | string[] | boolean;
  mediaValues?: MediaResponse[];
};

type LocationState = unknown;
type Pathname = History.Pathname;
type Search = History.Search;
type Hash = History.Hash;
type LocationKey = History.LocationKey;

type Input = string | URL | Request;
type RetryOptions = {
  limit?: number;
  methods?: string[];
  statusCodes?: number[];
  afterStatusCodes?: number[];
  maxRetryAfter?: number;
};
type NormalizedOptions = RequestInit & {
  method: RequestInit["method"];
  credentials: RequestInit["credentials"];
  retry: RetryOptions;
  prefixUrl: string;
  onDownloadProgress?: Options["onDownloadProgress"];
};
type HTTPError = Error & {
  response: Response;
  request: Request;
  options: NormalizedOptions;
  constructor(response: Response, request: Request, options: NormalizedOptions);
};
type Options = Omit<RequestInit, "headers"> & {
  method?: "get" | "post" | "put" | "patch" | "head" | "delete" | string;
  headers?: HeadersInit | Record<string, string | undefined>;
  json?: unknown;
  parseJson?: (text: string) => unknown;
  searchParams?:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined
    | Record<string, string | number | boolean>
    | Array<Array<string | number | boolean>>;
  prefixUrl?: URL | string;
  retry?: RetryOptions | number;
  timeout?: number | false;
  hooks?: {
    beforeRequest?: Array<
      (
        request: Request,
        options: NormalizedOptions
      ) => Request | Response | void | Promise<Request | Response | void>
    >;
    beforeRetry?: Array<
      (options: {
        request: Request;
        options: NormalizedOptions;
        error: Error;
        retryCount: number;
      }) => typeof stop | void | Promise<typeof stop | void>
    >;
    afterResponse?: Array<
      (
        request: Request,
        options: NormalizedOptions,
        response: Response
      ) => Response | void | Promise<Response | void>
    >;
    beforeError?: Array<(error: HTTPError) => HTTPError | Promise<HTTPError>>;
  };
  throwHttpErrors?: boolean;
  onDownloadProgress?: (
    progress: {
      percent: number;
      transferredBytes: number;
      totalBytes: number;
    },
    chunk: Uint8Array
  ) => void;
  fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
};
type ResponsePromise = Promise<
  Response & {
    json: <T = unknown>() => Promise<T>;
  }
> & {
  arrayBuffer: () => Promise<ArrayBuffer>;
  blob: () => Promise<Blob>;
  formData: () => Promise<FormData>;
  json: <T = unknown>() => Promise<T>;
  text: () => Promise<string>;
};

declare interface GetEventSettingsResponse {
  id: string;
  name: string;
  timezone: string;
  startsAt: string;
  endsAt: string;
  location: string | null;
  locationLatLng: LocationLatLng | null;
  entryType: EventEntryType;
  isPublished: boolean;
  logoImage: MediaResponse | null;
  bannerImage: MediaResponse | null;
  faviconImage: MediaResponse | null;
  organizationId: string;
  organizationName: string;
  isUserRegistered: boolean;
  isUserOnboardingSpeaker: boolean;
  isAttendeeUserLinked: boolean;
  roleTags: AttendeeRole[];
  isAttended: boolean;
  announcementTwilioConversationSid: string | null;
  publicChatRoomTwilioConversationSid: string | null;
  webinarChatRoomTwilioConversationSid: string | null;
  announcementStreamChatChannelId: string | null;
  publicChatStreamChatChannelId: string | null;
  hasRegForm: boolean;
  hasDefaultRegForm: boolean;
  hasNetworkingLounge: boolean;
  hasTicketing: boolean;
  featureConfig: EventFeatureConfig;
  defaultLocale: string;
  supportedLocales: string[];
  types: EventType[];
  canAttendEvent: boolean;
  canBypassAttendRestriction: boolean;
  canEnterEventEarlier: boolean;
  halls: Array<Pick<BoothHall, "id" | "name">>;
  attendeeVisibilityControl: AttendeeVisibilityControl;
  themeColorConfig: Partial<{
    primaryColor: string | null;
    secondaryColor: string | null;
  }>;
  navItems: NavItemResponse[];
  createdAt: string;
  customDomain: string | null;
  postEventBufferMins: number;
  isDemo: boolean;
  isMagicLinkActive: boolean;
  isEnabledPostEventSurvey: boolean;
  isOverRegistrationAllowed: boolean;
  isApprovalMechanismEnabled: boolean;
  socialSharingSettings: GetSocialSharingSettingsResponse;
  eventShortUrl: string;
  onsitePollingSetId: string | null;
  onsitePollingSetTwilioSyncSid: string | null;
  format: EventFormatType | null;
  isPushNotificationEnabled: boolean;
  injectedScript: string | null;
  emailReminderEnabledControl: Record<EmailReminderControl, boolean>;
  isEntrySettingEnabled: boolean;
  entryStartsAt: string | null;
  entryEndsAt: string | null;
}

declare interface UserSession {
  id: string;
  name: string;
  email: string;
  fullContactNo?: string;
  jobTitle: string | null;
  organization: string | null;
  areaCode: string | null;
  contactNo: string | null;
  country: string | null;
  city: string | null;
  profilePic: MediaResponse | null;
  createdAt: string;
  locale: string;
  organizations?: Array<{
    id: string;
    name: string;
    releaseStage: ReleaseStage;
    createdAt: string;
  }>;
  signupIntent: SignupIntent | null;
  signupMethod: SignupMethod | null;
  mobileCountryCode: string | null;
  mobileNo: string | null;
  signupSite: SignupSite | null;
  contactEmail: string | null;
  isEmsUser: boolean;
}

declare interface GetCurrentAttendeeResponse {
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  secondaryEmails: string[] | null;
  fullContactNo: string | null;
  jobTitle: string | null;
  organization: string | null;
  areaCode: string | null;
  contactNo: string | null;
  country: string | null;
  city: string | null;
  profilePic: MediaResponse | null;
  contactEmail: string | null;
  isMeetupEnabled: boolean;
  meetupConfig: {
    exhibitorId: string;
    exhibitorAuthToken: string;
    representativeId: string;
  } | null;
  customFields: Record<string, CustomField>;
  webinarSessionBookmarks: string[];
  isLeadCaptureLicenseEnabled: boolean;
  ticketClassId?: string;
  eventRegFormId?: string;
}

declare interface Location<S = LocationState> {
  pathname: Pathname;
  search: Search;
  state: S;
  hash: Hash;
  key?: LocationKey | undefined;
}

declare interface KyInstance {
  (url: Input, options?: Options): ResponsePromise;
  get: (url: Input, options?: Options) => ResponsePromise;
  post: (url: Input, options?: Options) => ResponsePromise;
  put: (url: Input, options?: Options) => ResponsePromise;
  delete: (url: Input, options?: Options) => ResponsePromise;
  patch: (url: Input, options?: Options) => ResponsePromise;
  head: (url: Input, options?: Options) => ResponsePromise;
  create: (defaultOptions?: Options) => KyInstance;
  extend: (defaultOptions?: Options) => KyInstance;
}

declare interface i18n {
  language: string;
  languages: readonly string[];
  resolvedLanguage?: string;
}`;
