import React from 'react';
import {
  Button,
  ListButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  BooleanField,
  useRecordContext,
} from 'react-admin';
import { Link, useParams } from 'react-router-dom';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import IconEdit from '@material-ui/icons/Edit';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';
import { DateTimeField } from '@/components/common/DateTimeField';
import { ImpersonateInPortalButton } from '@/components/impersonate-token/ImpersonateInPortalButton';
import { DeleteImpersonateTokenButton } from '@/components/impersonate-token/DeleteImpersonateTokenButton';
import { ImpersonateToken } from '@/types/impersonate-token';
import { WarningTypography } from '@/components/common/WarningTypography';

const ImpersonateTokenShowActions: React.FC<ShowProps> = (props) => {
  const { basePath } = props;
  const { id } = useParams<{ id: string }>();
  const record = useRecordContext<ImpersonateToken>(props) as
    | ImpersonateToken
    | undefined;
  return (
    <TopToolbar>
      {record?.isValid ? (
        <>
          <ImpersonateInPortalButton host="union" />
          <ImpersonateInPortalButton />
          <ImpersonateInPortalButton host="cn" />
          <DeleteImpersonateTokenButton impersonateTokenId={id} />
          <Button
            component={Link}
            to={`/impersonate-tokens/${id}`}
            label="Edit"
            title="Edit"
          >
            <IconEdit />
          </Button>
        </>
      ) : null}

      <ListButton basePath={basePath} label="Back" icon={<IconChevronLeft />} />
    </TopToolbar>
  );
};

export const ImpersonateTokenShow: React.FC<ShowProps> = (props) => {
  return (
    <Show actions={<ImpersonateTokenShowActions {...props} />} {...props}>
      <SimpleShowLayout>
        <ClipboardTextField label="Token Id" source="id" />
        <WarningTypography>
          Impersonate is really dangerous and all operation will be tracked,
          please use it carefully.
        </WarningTypography>

        <ClipboardTextField
          isShowButtonOnly
          label="impersonate Token Url (UNION)"
          source="impersonateTokenUrlForUnion"
        />
        <ClipboardTextField
          isShowButtonOnly
          label="impersonate Token Url"
          source="impersonateTokenUrl"
        />
        <ClipboardTextField
          isShowButtonOnly
          label="impersonate Token Url (CN)"
          source="impersonateTokenUrlForCN"
        />
        <BooleanField key="isValid" fullWidth source="isValid" />
        <TextField source="userId" />
        <TextField source="user.name" label="User Name" />
        <TextField source="user.email" label="User Email" />

        <br />
        <DateTimeField source="createdAt" />
        <TextField
          source="createdByInternalUserEmail"
          label="Created By Email"
        />

        <br />
        <DateTimeField source="consumedAt" />

        <br />
        <DateTimeField source="expiredAt" />

        <br />
        <TextField
          source="deletedByInternalUserEmail"
          label="Deleted By Email"
        />
        <DateTimeField source="deletedAt" />

        <br />
        <TextField label="Internal remarks" source="note" emptyText="-" />
      </SimpleShowLayout>
    </Show>
  );
};
