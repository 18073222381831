import React, { useCallback } from 'react';
import {
  SimpleForm,
  EditProps,
  required,
  Toolbar,
  SaveButton,
  ToolbarProps,
  ButtonProps,
  BooleanInput,
  Title,
  useQuery,
  Loading,
  useNotify,
  useUpdate,
} from 'react-admin';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { useFormStyles } from '@/hooks/useFormStyles';
import { FormContainer } from '@/components/common/FormContainer';
import { EventXSystemConfig } from '@/types';
import { Card } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const SystemConfigEditToolbar: React.FC<
  ToolbarProps & {
    onSave: ButtonProps['onSave'];
  }
> = (props) => {
  const { onSave, ...restProps } = props;

  return (
    <Toolbar {...restProps}>
      <SaveButton
        style={{
          marginRight: '20px',
        }}
        onSave={onSave}
      />
    </Toolbar>
  );
};

export const SystemConfigEdit: React.FC<EditProps> = () => {
  const classes = useFormStyles();
  const notify = useNotify();
  const history = useHistory();
  const [update] = useUpdate('system-config', '');
  const [data, setData] = React.useState<EventXSystemConfig>();
  useQuery(
    {
      type: 'getOne',
      resource: 'system-config',
      payload: {
        id: '',
      },
    },
    {
      onSuccess({ data }: { data: EventXSystemConfig }) {
        setData(data);
      },
    },
  );

  const handleSubmit = useCallback(
    async (values: EventXSystemConfig) => {
      await update(
        {
          payload: {
            data: {
              isMultiTenantEnabled: values.isMultiTenantEnabled,
            },
          },
        },
        {
          onSuccess: () => {
            notify('Subscription updated');
            history.push(`/system-config`);
          },
        },
      );
    },
    [history, notify, update],
  );

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Title title="System Config" />
      <TopToolbarWithBackButton path="/system-config" />
      <Card>
        <SimpleForm
          record={data}
          component={FormContainer}
          toolbar={
            <SystemConfigEditToolbar
              record={data}
              onSave={async (values) =>
                handleSubmit(values as EventXSystemConfig)
              }
            />
          }
        >
          <BooleanInput
            className={classes.formInput}
            source="isMultiTenantEnabled"
            label="Is Multi Tenant DB Enabled"
            validate={[required()]}
          />
        </SimpleForm>
      </Card>
    </>
  );
};
