import { memo } from 'react';
import {
  ResourceComponentProps,
  useRecordContext,
  Datagrid,
  TextField,
  BooleanField,
} from 'react-admin';
import { TeamMemberDeleteButton } from '@/components/common/buttons';

export const EventManagerDatagrid: React.FC<ResourceComponentProps> = memo(
  (props) => {
    const record = useRecordContext(props);

    return (
      <Datagrid>
        <TextField sortable={false} source="invitationEmail" />
        <BooleanField sortable={false} source="isAccepted" />
        <TeamMemberDeleteButton
          resourceId={record.id as string}
          redirect={false}
        />
      </Datagrid>
    );
  },
);
