import { memo } from 'react';
import {
  Record,
  ResourceComponentProps,
  Datagrid,
  TextField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { WebinarSessionQuestionTruncateButton } from './WebinarSessionQuestionTruncateButton';
import { WebinarSessionPollTruncateButton } from './WebinarSessionPollTruncateButton';

export const EventWebinarSessionDatagrid: React.FC<ResourceComponentProps> =
  memo((props) => {
    const eventRecord = useRecordContext(props);

    return (
      <Datagrid>
        <TextField sortable={false} source="id" />
        <TextField sortable={false} source="name" />
        <FunctionField
          render={(webinarSessionRecord?: Record) =>
            webinarSessionRecord ? (
              <WebinarSessionQuestionTruncateButton
                label="Truncate QNA"
                eventId={eventRecord.id as string}
                webinarSessionId={webinarSessionRecord.id as string}
              />
            ) : null
          }
        />
        <FunctionField
          render={(webinarSessionRecord?: Record) =>
            webinarSessionRecord ? (
              <WebinarSessionPollTruncateButton
                disabled={webinarSessionRecord?.pollingSetId === null}
                label="Truncate Polling"
                eventId={eventRecord.id as string}
                pollingSetId={webinarSessionRecord.pollingSetId as string}
              />
            ) : null
          }
        />
      </Datagrid>
    );
  });
