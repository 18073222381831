import React, { memo } from 'react';
import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  NumberInput,
  DateTimeInput,
  required,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { FormContainer } from '@/components/common/FormContainer';
import { useFormStyles } from '@/hooks/useFormStyles';

export const EventInternalInfoCreate: React.FC<CreateProps> = memo((props) => {
  const classes = useFormStyles();
  const { eventId } = useParams<{ eventId: string }>();
  const basePath = `events/${eventId}`;

  return (
    <Create
      title={`Create Event Internal Info #${eventId}`}
      resource={`${basePath}/internal-info`}
      basePath={`${basePath}/internal-info`}
      actions={<TopToolbarWithBackButton path={`/${basePath}/show`} />}
      {...props}
    >
      <SimpleForm
        redirect={`/${basePath}/internal-info`}
        component={FormContainer}
      >
        <TextInput
          disabled
          source="eventId"
          label="Event ID"
          defaultValue={eventId}
          className={classes.formInput}
          min={0}
          validate={[required()]}
        />
        <NumberInput
          source="estimatedAttendeeCount"
          className={classes.formInput}
          min={0}
          validate={[required()]}
        />
        <NumberInput
          source="estimatedHostAndSpeakerCount"
          className={classes.formInput}
          min={0}
        />
        <NumberInput
          source="estimatedWebinarSessionCount"
          className={classes.formInput}
          min={0}
        />
        <NumberInput
          source="estimatedProductCount"
          className={classes.formInput}
          min={0}
        />
        <NumberInput
          source="estimatedBoothCount"
          className={classes.formInput}
          min={0}
        />
        <NumberInput
          source="estimatedVideoLibraryItemCount"
          className={classes.formInput}
          min={0}
        />
        <DateTimeInput
          source="promotionStartsAt"
          className={classes.formInput}
        />
        <DateTimeInput source="liveStartsAt" className={classes.formInput} />
        <DateTimeInput source="liveEndsAt" className={classes.formInput} />
      </SimpleForm>
    </Create>
  );
});
