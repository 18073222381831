import { TopToolbar, Button } from 'react-admin';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link, useHistory } from 'react-router-dom';
import { useMemo } from 'react';

export const TopToolbarWithBackButton: React.FC<{ path?: string }> = ({
  path,
  children,
}) => {
  const history = useHistory();

  const linkButton = useMemo(() => {
    if (path) {
      return (
        <Button
          startIcon={<IconChevronLeft />}
          component={Link}
          to={path}
          label="Back"
          title="Back"
        />
      );
    }

    return (
      <Button
        startIcon={<IconChevronLeft />}
        label="Back"
        title="Back"
        onClick={() => {
          history.goBack();
        }}
      />
    );
  }, [history, path]);

  return (
    <TopToolbar>
      <>
        {children}
        {linkButton}
      </>
    </TopToolbar>
  );
};
