import {
  Datagrid,
  EditButton,
  ResourceComponentProps,
  ShowButton,
  TextField,
} from 'react-admin';
import { DateTimeField } from '../../components/common/DateTimeField';

const CustomDomainDatagrid: React.FC<ResourceComponentProps> = () => {
  return (
    <Datagrid>
      <TextField sortable={false} source="id" label="Custom Domain ID" />
      <TextField
        sortable={false}
        source="organizationId"
        label="Organization ID"
      />
      <TextField sortable={false} source="domain" label="Domain" />
      <DateTimeField hideLabel source="createdAt" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  );
};

export default CustomDomainDatagrid;
