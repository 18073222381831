import React, { memo } from 'react';
import {
  SimpleForm,
  EditProps,
  SaveButton,
  ToolbarProps,
  Toolbar,
  useGetOne,
  Edit,
  required,
  TextInput,
  Loading,
  DateTimeInput,
  Record as RaRecord,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { dateTimeValidation } from '@/utils/react-admin-validation';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { FormContainer } from '@/components/common/FormContainer';
import { useFormStyles } from '@/hooks/useFormStyles';

const EventEditToolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export const EventEdit: React.FC<EditProps> = memo(() => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data } = useGetOne('events', eventId);
  const basePath = `/events/${eventId}/show`;
  const classes = useFormStyles();

  const transform = (
    data: RaRecord & {
      startsAt?: Date;
      endsAt?: Date;
    },
  ) => {
    return {
      id: data.id,
      startsAt: data.startsAt,
      endsAt: data.endsAt,
    };
  };

  return data ? (
    <Edit
      id={eventId}
      title={`Edit Event #${eventId}`}
      resource="events"
      basePath="events"
      actions={<TopToolbarWithBackButton path={basePath} />}
      mutationMode="pessimistic"
      transform={transform}
    >
      <SimpleForm
        undoable
        record={data}
        toolbar={<EventEditToolbar />}
        redirect={basePath}
        component={FormContainer}
      >
        <TextInput
          disabled
          className={classes.formInput}
          source="eventId"
          label="ID"
          defaultValue={eventId}
          validate={[required()]}
        />
        <DateTimeInput
          className={classes.formInput}
          source="startsAt"
          validate={[required(), dateTimeValidation]}
        />
        <DateTimeInput
          className={classes.formInput}
          source="endsAt"
          validate={[required(), dateTimeValidation]}
        />
      </SimpleForm>
    </Edit>
  ) : (
    <Loading />
  );
});
