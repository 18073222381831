import React from 'react';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  BooleanField,
  Button,
  Link,
  ListButton,
  Pagination,
  ReferenceManyField,
  SelectField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from 'react-admin';
import IconEdit from '@material-ui/icons/Edit';
import { useParams } from 'react-router-dom';
import { DefaultLandingSystem } from '@/types';
import { UserImpersonateTokenDatagrid } from '@/components/impersonate-token/UserImpersonateTokenDatagrid';
import { UserOrganizationDatagrid } from './organizations/UserOrganizationDatagrid';
import { UserPublicApiTokenDatagrid } from '@/components/public-api-token/UserPublicApiTokenDatagrid';

const UserShowActions: React.FC<ShowProps> = ({ basePath }) => {
  const { id } = useParams<{ id: string }>();
  return (
    <TopToolbar>
      <Button component={Link} to={`/users/${id}`} label="Edit" title="Edit">
        <IconEdit />
      </Button>
      <ListButton basePath={basePath} label="Back" icon={<IconChevronLeft />} />
    </TopToolbar>
  );
};

export const UserShow: React.FC<ShowProps> = (props) => {
  const { id: userId } = useParams<{
    id: string;
  }>();

  return (
    <Show actions={<UserShowActions {...props} />} {...props}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <BooleanField
          key="isEmsUser"
          fullWidth
          label="is legacy ems user"
          source="isEmsUser"
        />
        <SelectField
          source="defaultLandingSystem"
          choices={[
            { id: DefaultLandingSystem.ESAAS, name: 'ESAAS' },
            { id: DefaultLandingSystem.EMS, name: 'EMS' },
          ]}
        />

        <ReferenceManyField
          fullWidth
          label="Organization"
          source="organizationId"
          reference="organizations"
          target="userId"
          sort={{ field: 'createdAt', order: 'DESC' }}
          filter={{
            createdByUserId: userId,
          }}
          pagination={<Pagination />}
        >
          <UserOrganizationDatagrid />
        </ReferenceManyField>

        <ReferenceManyField
          reference="impersonate-tokens"
          label="Impersonate Tokens"
          target="userId"
          sort={{ field: 'createdAt', order: 'DESC' }}
          pagination={<Pagination />}
        >
          <UserImpersonateTokenDatagrid />
        </ReferenceManyField>

        <ReferenceManyField
          reference="public-api-tokens"
          label="Public Api Tokens"
          target="userId"
          sort={{ field: 'createdAt', order: 'DESC' }}
          pagination={<Pagination />}
        >
          <UserPublicApiTokenDatagrid />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
