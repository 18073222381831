export const convertCamelCaseToFirstUppercase = (source: string) => {
  const result = source.replace(/([A-Z])/g, ' $1').toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const convertDatetimeFormat = (source: string) => {
  const date = new Date(source).toLocaleDateString();
  const time = new Date(source).toLocaleTimeString();
  return `${date} ${time}`;
};
