import React, { memo } from 'react';
import {
  EditProps,
  SaveButton,
  ToolbarProps,
  Toolbar,
  SimpleForm,
  Edit,
  TextField,
  BooleanField,
  Record as ReactAdminRecord,
  TextInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { FormContainer } from '@/components/common/FormContainer';
import { pick } from 'lodash-es';

const NameBadgeCustomTemplateToolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const transformImpersonateTokenData = (data: ReactAdminRecord) => {
  return {
    id: data.id,
    ...pick(data, ['note']),
  };
};

export const ImpersonateTokenEdit: React.FC<EditProps> = memo((props) => {
  const { id } = useParams<{
    id: string;
  }>();

  return (
    <Edit
      resource="impersonate-token"
      title={`Edit ImpersonateToken #${id}`}
      actions={<TopToolbarWithBackButton />}
      transform={transformImpersonateTokenData}
      mutationMode="optimistic"
      {...props}
    >
      <SimpleForm
        toolbar={<NameBadgeCustomTemplateToolbar />}
        component={FormContainer}
        redirect="show"
      >
        <TextField label="TokenId" source="id" />
        <BooleanField key="isValid" fullWidth source="isValid" />
        <TextField source="userId" />
        <TextField source="user.name" label="User Name" />
        <TextField source="user.email" label="User Email" />
        <TextInput source="note" label="Internal remarks" />
      </SimpleForm>
    </Edit>
  );
});
