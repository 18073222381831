import { memo, useCallback, useState } from 'react';
import {
  Button,
  downloadCSV,
  FieldProps,
  Link,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import ImportExport from '@material-ui/icons/ImportExport';
import convertor from 'json2csv';
import * as Sentry from '@sentry/react';

interface StreamChatMessage {
  user: {
    name: string;
  };
  text: string;
  created_at: string;
}

interface ExportResultProp {
  url: string;
  messages: StreamChatMessage[];
}

export const EventChatExportButton: React.FC<FieldProps> = memo((props) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const record = useRecordContext(props);

  const handleExportChat = useCallback(
    async (taskId: string | number) => {
      try {
        const { data } = await dataProvider.getOne(
          `stream-chat/export/${record.id}`,
          {
            id: taskId,
          },
        );
        if (data.result) {
          setLoading(false);
          const { messages } = data.result as ExportResultProp;
          if (!messages.length) {
            // eslint-disable-next-line no-alert
            window.alert('No public chat messages found');
            return;
          }

          const transformed = messages.map((message: StreamChatMessage) => ({
            name: message.user.name,
            text: message.text,
            createdAt: new Date(message.created_at).toLocaleString(),
          }));
          const csv = convertor.parse(transformed);
          downloadCSV(csv, 'export');
        } else {
          await handleExportChat(taskId);
        }
      } catch (err) {
        setLoading(false);
        // eslint-disable-next-line no-alert
        window.alert('Something wrong on exporting chat');
        Sentry.captureException(err);
      }
    },
    [dataProvider, record.id],
  );

  const handleExportRequest = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await dataProvider.create(
        `stream-chat/request-export/${record.id}/public-chat`,
        { data: {} },
      );
      await handleExportChat(data.id);
    } catch (err) {
      setLoading(false);
      // eslint-disable-next-line no-alert
      window.alert('Something wrong on exporting chat');
      Sentry.captureException(err);
    }
  }, [dataProvider, handleExportChat, record.id]);

  return (
    <Button
      component={Link}
      label={loading ? 'Fetching export…' : 'Export Public Chatroom History'}
      title="Export Public Chatroom History"
      disabled={loading}
      onClick={handleExportRequest}
    >
      <ImportExport />
    </Button>
  );
});
