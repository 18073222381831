import React from 'react';
import {
  Button,
  EditButton,
  ResourceComponentProps,
  ShowButton,
  ShowButtonProps,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { Datagrid, DateField, EditButtonProps, List } from 'ra-ui-materialui';
import { DefaultListPagination } from '@/components/common/DefaultListPagination';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';
import { releaseStages, TransformedReleaseControl } from '@/types';
import { Link } from 'react-router-dom';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { ReleaseControlStageBooleanField } from '@/components/release-control/ReleaseControlStageBooleanField';
import { pick } from 'lodash-es';
import { Box } from '@material-ui/core';
import { ReleaseControlNoteField } from '@/components/release-control/ReleaseControlNoteField';

const filters = [
  <TextInput
    key="id"
    alwaysOn
    label="ID"
    source="id"
    placeholder="Must be a valid FeatureFlagKey"
  />,
];

const ReleaseControlEditOrInitButton = (props: EditButtonProps) => {
  const record = useRecordContext<TransformedReleaseControl>();
  const cloneRecord = pick(record, ['id']);
  if (!record.isInitialized) {
    return (
      <Button
        component={Link}
        startIcon={<LibraryAddIcon />}
        label="Init Key"
        to={{
          pathname: '/release-controls/create',
          state: {
            record: cloneRecord,
          },
        }}
      />
    );
  }

  return <EditButton {...props} />;
};

const ReleaseControlShowButton = (props: ShowButtonProps) => {
  const record = useRecordContext<TransformedReleaseControl>();
  if (!record.isInitialized) {
    return <span>N/A</span>;
  }

  return <ShowButton {...props} />;
};

export const ReleaseControlList: React.ComponentType<
  ResourceComponentProps<Record<string, string>, any, any>
> = (props) => {
  const prefix = 'releaseControl';
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={50}
      pagination={<DefaultListPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={filters}
      exporter={false}
      hasCreate={false}
    >
      <Datagrid>
        <Box maxWidth="450px">
          <ClipboardTextField source="id" />
          <ReleaseControlNoteField source="releaseControl.note" />
        </Box>
        {releaseStages.map((stage) => {
          return (
            <ReleaseControlStageBooleanField
              key={stage}
              label={`Release Stage: ${stage}`}
              source={`${prefix}.${stage}`}
              sortable={false}
            />
          );
        })}
        <ReleaseControlStageBooleanField
          label="Is Backward Compatible"
          source={`${prefix}.isBackwardCompatible`}
          sortable={false}
        />
        <DateField
          source={`${prefix}.applyToEventCreatedAfter`}
          label="Apply To Event Created After"
          sortable={false}
        />
        <DateField
          source={`${prefix}.createdAt`}
          label="Created At"
          sortable={false}
        />
        <DateField
          source={`${prefix}.updatedAt`}
          label="Updated At"
          sortable={false}
        />
        <ReleaseControlShowButton />
        <ReleaseControlEditOrInitButton />
      </Datagrid>
    </List>
  );
};
