import { memo } from 'react';
import { constructFullEventUrl, eventRoutes } from '@/routes/eventRoutes';
import { FieldProps, UrlField, useRecordContext } from 'react-admin';

export const EventUrlField = memo((props: FieldProps) => {
  const record = useRecordContext(props);

  return (
    <UrlField
      source="name"
      href={constructFullEventUrl(
        eventRoutes.eventPage({ eventId: record.id as string }),
      )}
      target="_blank"
      style={{ textDecoration: 'none' }}
    />
  );
});
