import { memo } from 'react';
import { TextFieldProps, useRecordContext, Labeled } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
  convertCamelCaseToFirstUppercase,
  convertDatetimeFormat,
} from '@/utils';

export const DateTimeField: React.FC<
  TextFieldProps & {
    hideLabel?: boolean;
  }
> = memo((props) => {
  const { className, source, hideLabel } = props;
  const record = useRecordContext(props);
  if (!record || !source) {
    return null;
  }

  const DateTimeText = (
    <Typography component="span" variant="body2" className={className}>
      {record[source] ? convertDatetimeFormat(record[source]) : '-'}
    </Typography>
  );

  return hideLabel ? (
    DateTimeText
  ) : (
    <Labeled label={convertCamelCaseToFirstUppercase(source)}>
      {DateTimeText}
    </Labeled>
  );
});
