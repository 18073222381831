import React from 'react';
import {
  Edit,
  SimpleForm,
  EditProps,
  Toolbar,
  SaveButton,
  useGetOne,
  Loading,
  BooleanInput,
  Record as ReactAdminRecord,
  DateTimeInput,
  TextInput,
} from 'react-admin';

import { Tooltip } from '@material-ui/core';
import { FormContainer } from '@/components/common/FormContainer';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { useParams } from 'react-router-dom';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';
import { releaseStages, TransformedReleaseControl } from '@/types';
import { pick } from 'lodash-es';
import { checkTransformedReleaseControlCanRelease } from './utils';
import { useFormStyles } from '@/hooks/useFormStyles';
import { WarningTypography } from '@/components/common/WarningTypography';

const ReleaseControlEditToolbar: React.FC<{ isDisabled: boolean }> = ({
  isDisabled,
  ...props
}) => (
  <Toolbar>
    <Tooltip
      title={isDisabled ? 'Default release control cannot be edited!' : ''}
    >
      <span>
        <SaveButton {...props} disabled={isDisabled} />
      </span>
    </Tooltip>
  </Toolbar>
);

function transformSubmitData(data: ReactAdminRecord) {
  const { releaseControl } = data as TransformedReleaseControl;
  return pick(releaseControl, [
    'id',
    'applyToEventCreatedAfter',
    'note',
    ...releaseStages,
  ]);
}

export const ReleaseControlEdit: React.FC<EditProps> = (props) => {
  const prefix = 'releaseControl';
  const { id } = useParams<{ id: string }>();
  const classes = useFormStyles();

  const { data: releaseControlData, loaded: isLoaded } =
    useGetOne<TransformedReleaseControl>('release-controls', id);

  if (!isLoaded) {
    return <Loading />;
  }

  const isEditable = releaseControlData?.isInitialized;
  const isSupportToRelease =
    checkTransformedReleaseControlCanRelease(releaseControlData);

  return (
    <Edit
      undoable
      resource="release-controls"
      mutationMode="pessimistic"
      title="Edit Release Control"
      transform={transformSubmitData}
      actions={<TopToolbarWithBackButton />}
      {...props}
    >
      <SimpleForm
        toolbar={
          <ReleaseControlEditToolbar {...props} isDisabled={!isEditable} />
        }
        component={FormContainer}
        redirect="show"
      >
        <ClipboardTextField label="ID" source="id" />
        {releaseStages.map((stage) => {
          return (
            <BooleanInput
              key={stage}
              label={`Release Stage: ${stage}`}
              source={`${prefix}.${stage}`}
            />
          );
        })}

        {isSupportToRelease ? (
          <>
            <WarningTypography>
              To update applyToEventCreatedAfter, please contact engineer to
              handle backward compatibility problem
            </WarningTypography>
            <DateTimeInput
              label="Apply To Event Created After"
              source={`${prefix}.applyToEventCreatedAfter`}
              disabled={!isSupportToRelease}
            />
          </>
        ) : null}

        <TextInput
          className={classes.formInput}
          label="Internal remarks"
          source={`${prefix}.note`}
        />
      </SimpleForm>
    </Edit>
  );
};
