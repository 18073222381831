import { useCallback, useMemo } from 'react';
import {
  Create,
  CreateProps,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetOne,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '../../components/common/TopToolbarWithBackButton';

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

interface AttendeeTagsResult {
  id: string;
  attendeeTags: Array<{
    id: string;
    name: string;
  }>;
  count: number;
}

export const EventLeadFinderCopyLeads: React.FC<CreateProps> = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const basePath = `events/${eventId}/lead-finder/copy-leads`;
  const redirectPath = `events/${eventId}/show`;

  const { data, loading } = useGetOne<AttendeeTagsResult>(
    'events',
    `${eventId}/attendee-tags`,
  );

  const sourceTags = useMemo(() => {
    return (
      data?.attendeeTags.map((tag) => ({
        id: tag.id,
        name: tag.name,
      })) ?? []
    );
  }, [data]);

  const validate = useCallback((values: Record<string, string>) => {
    const errors: Record<string, string> = {};
    if (!values.toEventId) {
      errors.toEventId = 'Destination Event ID is required';
    }

    if (!uuidRegex.test(values.toEventId ?? '')) {
      errors.toEventId = 'Destination Event ID is invalid';
    }

    if (values.fromTagId && !uuidRegex.test(values.fromTagId ?? '')) {
      errors.fromTagId = 'Source Tag ID is invalid';
    }

    if (!values.toTagName) {
      errors.toTagName = 'Destination Tag Name is required';
    }

    return errors;
  }, []);

  return (
    <Create
      basePath={basePath}
      resource="lead-finder/copy-leads"
      actions={<TopToolbarWithBackButton path={redirectPath} />}
      title="Copy Leads"
    >
      <SimpleForm validate={validate} redirect={redirectPath}>
        <TextInput
          required
          disabled
          fullWidth
          source="fromEventId"
          label="Source Event ID"
          defaultValue={eventId}
        />
        <TextInput
          required
          fullWidth
          source="toEventId"
          label="Destination Event ID"
        />
        <SelectInput
          fullWidth
          resettable
          disabled={loading}
          source="fromTagId"
          choices={sourceTags}
        />
        <TextInput
          required
          fullWidth
          source="toTagName"
          label="Destination Tag Name"
        />
      </SimpleForm>
    </Create>
  );
};
