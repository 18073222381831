import { ComponentType } from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { CustomDomainPathNameTextInput } from '../../components/common/CustomDomainPathNameTextInput';

export const CustomDomainPathMappingEdit: ComponentType = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <CustomDomainPathNameTextInput fullWidth source="pathName" />
      </SimpleForm>
    </Edit>
  );
};
