import { memo } from 'react';
import {
  ResourceComponentProps,
  Datagrid,
  TextField,
  NumberField,
  useRecordContext,
} from 'react-admin';
import { CreditSystemBalanceEditButton } from './buttons/CreditSystemBalanceEditButton';
import { SubscriptionDetails } from '@/types';

export const SubscriptionCreditSystemBalanceDatagrid: React.FC<ResourceComponentProps> =
  memo((props) => {
    const record = useRecordContext<SubscriptionDetails>(props);

    return (
      <Datagrid>
        <TextField sortable={false} source="creditType" />
        <NumberField sortable={false} source="totalCredits" />
        <NumberField sortable={false} source="consumedCredits" />
        <NumberField sortable={false} source="netBalance" />
        <CreditSystemBalanceEditButton subscriptionId={record.id} />
      </Datagrid>
    );
  });
