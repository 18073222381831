import React, { memo, useCallback } from 'react';
import {
  Button,
  DateField,
  FieldProps,
  ListButton,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useGetOne,
  FunctionField,
  Record as ReactAdminRecord,
} from 'react-admin';
import { Link, useParams, useHistory } from 'react-router-dom';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import IconPayment from '@material-ui/icons/Payment';
import IconEvent from '@material-ui/icons/Event';
import IconEdit from '@material-ui/icons/Edit';
import { TeamMemberCreateButton } from '@/components/common/buttons';
import { StripeAccountField } from '@/components/common/StripeAccountField';
import { OrganizationManagerDatagrid } from '@/components/organization/OrganizationManagerDatagrid';
import { IdentifierLabelField } from '@/components/common/IdentifierField';
import { OrganizationFeatureConfigJsonViewer } from '@/components/feature-config-debugger';
import { OrganizationDetails, SubscriptionDetails } from '@/types';
import { CustomDomainCreateButton } from '@/components/common/buttons/CustomDomainCreateButton';
import CustomDomainDatagrid from '../custom-domain/CustomDomainDatagrid';

const OrganizationShowActions: React.FC<ShowProps> = () => {
  const { id } = useParams<{ id: string }>();
  const histroy = useHistory();

  const handleBack = useCallback(() => {
    histroy.goBack();
  }, [histroy]);

  return (
    <TopToolbar>
      <OrganizationShowAllEventsButton />
      <OrganizationShowActionsButton />
      <Button
        component={Link}
        to={`/organizations/${id}`}
        label="Edit"
        title="Edit"
      >
        <IconEdit />
      </Button>
      <ListButton
        label="Back"
        icon={<IconChevronLeft />}
        onClick={handleBack}
      />
    </TopToolbar>
  );
};

const OrganizationShowActionsButton: React.FC<FieldProps> = memo((props) => {
  const record = useRecordContext(props);
  return record?.id ? <SubscriptionShowButton {...props} /> : null;
});

const SubscriptionShowButton: React.FC<FieldProps> = memo((props) => {
  const record = useRecordContext(props);

  const link = `/organizations/${record.id}/subscriptions`;

  return (
    <Button
      label="Subscription List"
      component={Link}
      to={{
        pathname: link,
        state: {
          record: {
            organizationId: record.id,
          },
        },
      }}
    >
      <IconPayment />
    </Button>
  );
});

const OrganizationShowAllEventsButton: React.FC<FieldProps> = memo((props) => {
  const record = useRecordContext(props);
  return record?.id ? (
    <Button
      component={Link}
      to={`/events?filter={"organizationId":"${record.id}"}`}
      label="Events"
      title="Events"
    >
      <IconEvent />
    </Button>
  ) : null;
});

export const OrganizationShow: React.FC<ShowProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const { data: organizationDetails } = useGetOne<OrganizationDetails>(
    'organizations',
    id,
  );

  return (
    <Show actions={<OrganizationShowActions {...props} />} {...props}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <TextField source="name" />
        <ReferenceField
          label="User name"
          source="createdByUserId"
          reference="users"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="User email"
          source="createdByUserId"
          reference="users"
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          source="activeSubscriptionId"
          reference="subscriptions"
          link="show"
        >
          <FunctionField
            render={(record?: ReactAdminRecord) => {
              const sub = record as SubscriptionDetails;

              return (
                <span>
                  {`${sub?.id ?? ''}${sub?.note ? ` (${sub.note})` : ''}`}
                </span>
              );
            }}
          />
        </ReferenceField>
        <TextField source="releaseStage" />
        <DateField source="createdAt" />
        <DateField
          source="paymentOverduesAt"
          label="Account will be blocked at"
        />
        <ReferenceManyField
          reference="organization-managers"
          target="organizationId"
          label="Managers"
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <OrganizationManagerDatagrid />
        </ReferenceManyField>
        <TeamMemberCreateButton resource="organizations" />

        <ReferenceManyField
          reference="custom-domains"
          target="organizationId"
          label="Custom Domains"
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <CustomDomainDatagrid />
        </ReferenceManyField>

        <CustomDomainCreateButton
          resource="custom-domains"
          organizationId={id}
        />

        <StripeAccountField />

        <br />
        <IdentifierLabelField label="Feature Config Debugger" />
        {organizationDetails && (
          <OrganizationFeatureConfigJsonViewer
            organizationId={organizationDetails.id}
          />
        )}
      </SimpleShowLayout>
    </Show>
  );
};
