import React from 'react';
import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  SaveButton,
  Toolbar,
  BooleanInput,
} from 'react-admin';
import { FormContainer } from '@/components/common/FormContainer';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { useFormStyles } from '@/hooks/useFormStyles';

// Always enable the save button
const ReleaseControlCreateToolbar: React.FC = ({ ...props }) => (
  <Toolbar>
    <SaveButton {...props} />
  </Toolbar>
);

export const ReleaseControlCreate: React.FC<CreateProps> = (props) => {
  const classes = useFormStyles();
  return (
    <Create
      resource="release-controls"
      title="Create Release control"
      actions={<TopToolbarWithBackButton />}
      {...props}
    >
      <SimpleForm
        toolbar={<ReleaseControlCreateToolbar />}
        component={FormContainer}
        redirect="show"
      >
        <TextInput source="id" label="ID" />
        <BooleanInput
          fullWidth
          label="Is Backward Compatible"
          source="isBackwardCompatible"
        />
        <TextInput
          className={classes.formInput}
          label="Internal remarks"
          source="note"
        />
      </SimpleForm>
    </Create>
  );
};
