import React from 'react';
import {
  ShowButton,
  ResourceComponentProps,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'ra-ui-materialui';
import { DefaultListPagination } from '@/components/common/DefaultListPagination';

const filters = [
  <TextInput
    key="id"
    alwaysOn
    label="ID"
    source="id"
    placeholder="Must be a valid UUID"
  />,
  <TextInput key="name" alwaysOn label="Organization name" source="name" />,
  <ReferenceInput
    key="createdByUserId"
    alwaysOn
    label="Created by user (email)"
    reference="users"
    source="createdByUserId"
    allowEmpty={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <AutocompleteInput resettable optionText="email" />
  </ReferenceInput>,
];

export const OrganizationList: React.ComponentType<
  ResourceComponentProps<Record<string, string>, any, any>
> = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    perPage={50}
    pagination={<DefaultListPagination />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={filters}
    exporter={false}
  >
    <Datagrid>
      <TextField source="name" sortable={false} />
      <TextField source="releaseStage" />
      <ReferenceField
        label="Created by"
        source="createdByUserId"
        reference="users"
        link={false}
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="User Email"
        source="createdByUserId"
        reference="users"
        link={false}
        sortable={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <DateField source="createdAt" />
      <ShowButton />
    </Datagrid>
  </List>
);
