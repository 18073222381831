import { ClipboardTextField } from '@/components/common/ClipboardTextField';
import { FeatureConfigInput } from '@/components/common/FeatureConfigInput';
import { FormContainer } from '@/components/common/FormContainer';
import { IdentifierLabelField } from '@/components/common/IdentifierField';
import { OrganizationFeatureConfigInput } from '@/components/common/OrganizationFeatureConfigInput';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { useFormStyles } from '@/hooks/useFormStyles';
import { AVAILABLE_LOCALES } from '@/libs/translations';
import { PricingPlanDetails, pricingPlanFeatureConfigKeys } from '@/types';
import { Tooltip } from '@material-ui/core';
import { pick } from 'lodash-es';
import React from 'react';
import {
  Edit,
  EditProps,
  Loading,
  Record as ReactAdminRecord,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TranslatableInputs,
  useGetOne,
} from 'react-admin';
import { useParams } from 'react-router-dom';

const PricingPlanEditToolbar: React.FC<{ isDisabled: boolean }> = ({
  isDisabled,
  ...props
}) => (
  <Toolbar>
    <Tooltip
      title={isDisabled ? 'Default pricing plans cannot be edited!' : ''}
    >
      <span>
        <SaveButton {...props} disabled={isDisabled} />
      </span>
    </Tooltip>
  </Toolbar>
);

function transformSubmitData(data: ReactAdminRecord) {
  const result = data as PricingPlanDetails;
  result.defaultEventFeatureConfig = pick(
    result.defaultEventFeatureConfig,
    pricingPlanFeatureConfigKeys,
  );

  return pick(result, [
    'id',
    'note',
    'contentI18n',
    'organizationFeatureConfig',
    'defaultEventFeatureConfig',
  ]);
}

export const PricingPlanEdit: React.FC<EditProps> = (props) => {
  const classes = useFormStyles();
  const { id } = useParams<{ id: string }>();
  const { data: pricingPlanData, loaded: isLoaded } = useGetOne(
    'pricing-plans',
    id,
  );

  const isEditable =
    (pricingPlanData as PricingPlanDetails | undefined)?.key === null;

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <Edit
      undoable
      resource="pricing-plans"
      mutationMode="pessimistic"
      title="Edit Pricing Plan"
      transform={transformSubmitData}
      actions={<TopToolbarWithBackButton />}
      {...props}
    >
      <SimpleForm
        toolbar={<PricingPlanEditToolbar {...props} isDisabled={!isEditable} />}
        component={FormContainer}
        redirect="show"
      >
        <ClipboardTextField label="ID" source="id" />
        <TranslatableInputs locales={AVAILABLE_LOCALES}>
          <TextInput
            label="Name"
            source="contentI18n.name"
            disabled={!isEditable}
          />
        </TranslatableInputs>
        <IdentifierLabelField label="Organization Feature Config" />
        <OrganizationFeatureConfigInput
          source="organizationFeatureConfig"
          isDisabled={!isEditable}
        />
        <IdentifierLabelField label="Feature Configs" />
        <FeatureConfigInput
          mode="pricing-plan"
          source="defaultEventFeatureConfig"
          isDisabled={!isEditable}
        />
        <TextInput
          className={classes.formInput}
          disabled={!isEditable}
          label="Internal remarks"
          source="note"
        />
      </SimpleForm>
    </Edit>
  );
};
