import { EventDetails } from '@/types';
import { memo } from 'react';
import {
  Record,
  ResourceComponentProps,
  Datagrid,
  TextField,
  FunctionField,
  useRecordContext,
  useGetOne,
} from 'react-admin';
import { EventBoothChatExportButton } from './EventBoothChatExportButton';

export const EventBoothDatagrid: React.FC<ResourceComponentProps> = memo(
  (props) => {
    const eventRecord = useRecordContext(props);
    const { data: eventDetails } = useGetOne<EventDetails>(
      'events',
      eventRecord.id,
    );

    return (
      <Datagrid>
        <TextField sortable={false} source="id" />
        <TextField sortable={false} source="name" />
        <FunctionField
          render={(boothRecord?: Record) =>
            boothRecord && eventDetails ? (
              <EventBoothChatExportButton
                eventId={eventDetails.id}
                boothId={boothRecord.id as string}
              />
            ) : null
          }
        />
      </Datagrid>
    );
  },
);
