import { Button, useCreate, useNotify, useRefresh } from 'react-admin';
import { useCallback } from 'react';
import IconCreate from '@material-ui/icons/Create';

interface CreatePublicApiTokenButtonProps {
  userId: string;
}

const DEFAULT_EXPIRED_AT = new Date(
  Date.now() + 3600 * 1000 * 24 * 365,
).toISOString(); // 1 year

export const CreatePublicApiTokenButton: React.FC<
  CreatePublicApiTokenButtonProps
> = ({ userId }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [create] = useCreate(
    'public-api-tokens',
    {
      userId,
      expiredAt: DEFAULT_EXPIRED_AT,
    },
    {
      mutationMode: 'pessimistic',
      onFailure: (_) => {
        notify('Fail to create Public Api Token!', 'error');
      },
      onSuccess: (_) => {
        refresh();
        notify('Public Api Token created!');
      },
    },
  );

  const handleClick = useCallback(async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      'This will create public api token expire after 1 year, continue?',
    );

    if (!isConfirmed) {
      return;
    }

    await create();
  }, [create]);

  return (
    <Button
      style={{
        whiteSpace: 'nowrap',
      }}
      startIcon={<IconCreate />}
      label="Create Public Api Token"
      onClick={handleClick}
    />
  );
};
