import React from 'react';
import {
  Button,
  SimpleShowLayout,
  TextField,
  Show,
  ShowProps,
  ReferenceManyField,
  useRecordContext,
  RecordContextProvider,
} from 'react-admin';
import { Link, useParams } from 'react-router-dom';
import IconEdit from '@material-ui/icons/Edit';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { DateTimeField } from '@/components/common/DateTimeField';
import { SubscriptionCreditSystemBalanceDatagrid } from '@/components/credit-system/SubscriptionCreditSystemBalanceDatagrid';
import { SubscriptionDetails } from '@/types';
import { CreditSystemBalanceEditButton } from '@/components/credit-system/buttons/CreditSystemBalanceEditButton';
import { CreditSystemTypes } from '@/types/credit-system';

const CreditSystemBalanceCreateButton: React.FC = (props) => {
  const record = useRecordContext<SubscriptionDetails>(props);

  return (
    <>
      {CreditSystemTypes.map((creditType) => (
        <RecordContextProvider
          key={creditType}
          value={{
            creditType,
          }}
        >
          <CreditSystemBalanceEditButton
            key={creditType}
            subscriptionId={record.id}
            title={`Create ${creditType} balance`}
          />
        </RecordContextProvider>
      ))}
    </>
  );
};

const SubscriptionShowActions: React.FC = (props) => {
  const { id } = useParams<{ id: string }>();
  const record = useRecordContext<SubscriptionDetails>(props);

  return (
    <TopToolbarWithBackButton
      path={`/organizations/${record.organizationId}/subscriptions`}
    >
      <Button
        component={Link}
        to={`/subscriptions/${id}`}
        label="Edit"
        title="Edit"
      >
        <IconEdit />
      </Button>
    </TopToolbarWithBackButton>
  );
};

export const SubscriptionShow: React.FC<ShowProps> = (props) => {
  return (
    <Show actions={<SubscriptionShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceManyField
          reference="credit-system/balance"
          target="subscriptionId"
          label="Credit System Balance"
          sort={{ field: 'creditType', order: 'ASC' }}
        >
          <SubscriptionCreditSystemBalanceDatagrid />
        </ReferenceManyField>
        <CreditSystemBalanceCreateButton />
        <DateTimeField source="startedAt" emptyText="-" />
        <DateTimeField source="expiresAt" emptyText="-" />
        <TextField source="chargeMethod" />
        <TextField source="expiryBehavior" emptyText="-" />
        <TextField source="personInCharge" label="PIC" emptyText="-" />
        <TextField
          source="internalReferenceNo"
          label="Contract ID/Invoice No."
          emptyText="-"
        />
        <TextField source="note" label="Internal remarks" emptyText="-" />
      </SimpleShowLayout>
    </Show>
  );
};
