import React from 'react';
import {
  ShowButton,
  ResourceComponentProps,
  TextInput,
  TextField,
  TopToolbar,
  Button,
  Link,
  CreateButton,
} from 'react-admin';
import { Datagrid, List } from 'ra-ui-materialui';
import { Add } from '@material-ui/icons';
import { DefaultListPagination } from '@/components/common/DefaultListPagination';

const filters = [
  <TextInput
    key="id"
    alwaysOn
    label="ID"
    source="id"
    placeholder="Must be a valid UUID"
  />,
  <TextInput
    key="id"
    alwaysOn
    label="email"
    source="q"
    placeholder="Must be a valid email"
  />,
];

export const UserList: React.ComponentType<
  ResourceComponentProps<Record<string, string>, any, any>
> = (props) => (
  <List
    {...props}
    actions={
      <TopToolbar>
        <Button
          style={{ marginRight: '8px' }}
          component={Link}
          startIcon={<Add />}
          to="/users/import-lead-finder-demo-users"
          label="Import Lead Finder Demo Users"
        />
        <CreateButton />
      </TopToolbar>
    }
    perPage={50}
    pagination={<DefaultListPagination />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={filters}
    exporter={false}
  >
    <Datagrid>
      <TextField label="Name" source="name" sortable={false} />
      <TextField source="email" />
      <ShowButton />
    </Datagrid>
  </List>
);
