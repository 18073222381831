import { httpClient } from '@/App';
import { EventXSystemConfig } from '@/types';

export const getSystemConfig = async () => {
  const res = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL ?? ''}/system-config/`,
    {
      method: 'GET',
    },
  );
  return {
    data: res.json as EventXSystemConfig,
  };
};
