import { omit, pick } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import {
  SimpleForm,
  EditProps,
  Edit,
  required,
  SelectInput,
  TextInput,
  DateTimeInput,
  Toolbar,
  SaveButton,
  useGetOne,
  Loading,
  SaveButtonProps,
  useUpdate,
  useNotify,
  TranslatableInputs,
  useMutation,
  useRecordContext,
  NumberInput,
  ToolbarProps,
  ButtonProps,
} from 'react-admin';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import {
  PricingPlanDetails,
  SubscriptionDetails,
  pricingPlanFeatureConfigKeys,
} from '@/types';
import { useFormStyles } from '@/hooks/useFormStyles';
import { FormContainer } from '@/components/common/FormContainer';
import { useHistory, useParams } from 'react-router-dom';
import { FeatureConfigInput } from '@/components/common/FeatureConfigInput';
import { IdentifierLabelField } from '@/components/common/IdentifierField';
import { OrganizationFeatureConfigInput } from '@/components/common/OrganizationFeatureConfigInput';
import { AVAILABLE_LOCALES } from '@/libs/translations';

function redirect(basePath: string, id: string, data: SubscriptionDetails) {
  return `/organizations/${data.organizationId}/subscriptions`;
}

const SubscriptionEditToolbar: React.FC<
  ToolbarProps & {
    onSave: ButtonProps['onSave'];
  }
> = (props) => {
  const { onSave, ...restProps } = props;
  const notify = useNotify();
  const { id, note, organizationId } = useRecordContext(props.record);
  const [mutate] = useMutation(
    {
      type: 'update',
      resource: `organizations/${organizationId as string}`,
      payload: {
        id: 'activate-subscription',
        data: {
          activeSubscriptionId: id,
        },
      },
    },
    {
      onSuccess: () => {
        notify(`${String(note ?? id)}: (${id}) activated`);
      },
    },
  );

  return (
    <Toolbar {...restProps}>
      <SaveButton
        style={{
          marginRight: '20px',
        }}
        onSave={onSave}
      />
      <SaveButton
        label="Save & Activate"
        onSave={(values, redirect) => {
          void mutate();
          onSave?.(values, redirect);
        }}
      />
    </Toolbar>
  );
};

function transformSubmitData(data: Record<string, unknown>) {
  const result = data.pricingPlan as PricingPlanDetails;
  result.defaultEventFeatureConfig = pick(
    result.defaultEventFeatureConfig,
    pricingPlanFeatureConfigKeys,
  );

  return {
    ...omit(data, ['chargeMethod']),
    pricingPlan: pick(result, [
      'id',
      'note',
      'contentI18n',
      'organizationFeatureConfig',
      'defaultEventFeatureConfig',
    ]),
  };
}

export const SubscriptionEdit: React.FC<EditProps> = (props) => {
  const classes = useFormStyles();
  const { id } = useParams<{ id: string }>();
  const [update] = useUpdate('subscriptions', id);
  const notify = useNotify();
  const history = useHistory();

  const currentDate = useMemo(() => new Date(), []);

  const { data: subscriptionData, loaded: isLoaded } =
    useGetOne<SubscriptionDetails>('subscriptions', id);

  const handleSave = useCallback<NonNullable<SaveButtonProps['onSave']>>(
    async (values) => {
      if (subscriptionData?.chargeMethod === 'chargebee') {
        // eslint-disable-next-line no-alert
        const result = window.confirm(
          'This subscription is managed ChargeBee. It will be canceled and refunded (if applicable) immediately from ChargeBee. Continue?',
        );

        if (!result) {
          return;
        }
      }

      await update(
        {
          payload: {
            data: transformSubmitData({ ...values }),
          },
        },
        {
          onSuccess: ({ data }: { data: SubscriptionDetails }) => {
            notify('Subscription updated');
            history.push(`/organizations/${data.organizationId}/subscriptions`);
          },
        },
      );
    },
    [subscriptionData?.chargeMethod, update, notify, history],
  );

  const { data: pricingPlanData, loaded: isPricingPlanLoaded } = useGetOne(
    'pricing-plans',
    subscriptionData?.pricingPlanId ?? '',
    {
      enabled: Boolean(subscriptionData?.pricingPlanId),
    },
  );

  const isEditable =
    (pricingPlanData as PricingPlanDetails | undefined)?.key === null;

  if (!isLoaded || !isPricingPlanLoaded) {
    return <Loading />;
  }

  return (
    <Edit
      title={`Edit Organization ${
        subscriptionData?.organizationId ?? ''
      } Subscription`}
      resource="subscriptions"
      mutationMode="pessimistic"
      actions={<TopToolbarWithBackButton />}
      {...props}
    >
      <SimpleForm
        undoable
        redirect={redirect}
        component={FormContainer}
        toolbar={<SubscriptionEditToolbar onSave={handleSave} />}
        initialValues={{
          pricingPlan: pick(pricingPlanData, [
            'id',
            'note',
            'contentI18n',
            'organizationFeatureConfig',
            'defaultEventFeatureConfig',
          ]),
        }}
      >
        <TextInput
          disabled
          className={classes.formInput}
          source="organizationId"
          label="Organization ID"
          validate={[required()]}
        />
        <TranslatableInputs locales={AVAILABLE_LOCALES}>
          <TextInput
            label="Subscription Name"
            source="pricingPlan.contentI18n.name"
            disabled={!isEditable}
          />
        </TranslatableInputs>
        <DateTimeInput
          className={classes.formInput}
          source="startedAt"
          label="Started At"
          defaultValue={currentDate}
          validate={[required()]}
        />
        <DateTimeInput
          className={classes.formInput}
          source="expiresAt"
          label="Expires At"
          validate={[required()]}
        />
        <SelectInput
          className={classes.formInput}
          source="expiryBehavior"
          label="Expiry Behavior"
          choices={[
            { id: 'block_edit_create', name: 'Block Edit & Create' },
            { id: 'fallback_default', name: 'Fallback to Free Plan' },
          ]}
          validate={[required()]}
        />
        <TextInput
          className={classes.formInput}
          label="PIC"
          source="personInCharge"
        />
        <TextInput
          className={classes.formInput}
          label="Contract ID/Invoice No."
          source="internalReferenceNo"
        />
        <TextInput
          className={classes.formInput}
          label="Subscription Internal remarks"
          source="note"
        />
        <IdentifierLabelField label="Organization Feature Config" />
        <OrganizationFeatureConfigInput
          hideOrgEventRegistrants
          source="pricingPlan.organizationFeatureConfig"
          isDisabled={!isEditable}
        />
        <IdentifierLabelField label="Feature Configs" />
        <FeatureConfigInput
          mode="pricing-plan"
          source="pricingPlan.defaultEventFeatureConfig"
          isDisabled={!isEditable}
        />
        <NumberInput
          key="maxNumberOfOrgEventRegistrants"
          fullWidth
          className={classes.formInput}
          disabled={!isEditable}
          label="Max Number of Total Event Registrants in Organization(deprecated)"
          source="pricingPlan.organizationFeatureConfig.maxNumberOfOrgEventRegistrants"
        />
      </SimpleForm>
    </Edit>
  );
};
