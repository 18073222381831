import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  ShowProps,
  Button,
  BooleanField,
  Title,
  Loading,
  useQuery,
  TopToolbar,
} from 'react-admin';
import { Link } from 'react-router-dom';
import IconEdit from '@material-ui/icons/Edit';
import { Card } from '@material-ui/core';
import { EventXSystemConfig } from '@/types';

export const SystemConfigShow: React.FC<ShowProps> = () => {
  const [data, setData] = React.useState<EventXSystemConfig>();
  useQuery(
    {
      type: 'getOne',
      resource: 'system-config',
      payload: {
        id: '',
      },
    },
    {
      onSuccess({ data }: { data: EventXSystemConfig }) {
        setData(data);
      },
    },
  );

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Title title="System Config" />
      <TopToolbar>
        <Button
          component={Link}
          to="/system-config/edit"
          label="Edit"
          title="Edit"
        >
          <IconEdit />
        </Button>
      </TopToolbar>
      <Card>
        <SimpleShowLayout record={data}>
          <TextField label="" source="id" emptyText="--" />
          <BooleanField source="isMultiTenantEnabled" emptyText="--" />
          <TextField source="updatedAt" emptyText="--" />
        </SimpleShowLayout>
      </Card>
    </>
  );
};
