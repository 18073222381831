import { useMemo } from 'react';
import {
  Button,
  Link,
  ResourceComponentProps,
  useRecordContext,
} from 'react-admin';
import IconOpenInNew from '@material-ui/icons/OpenInNew';
import { ImpersonateTokenWithUserInfo } from '@/types/impersonate-token';

export const ImpersonateInPortalButton = (
  props: ResourceComponentProps & {
    host?: string;
  },
) => {
  const { host = '' } = props;
  const record: ImpersonateTokenWithUserInfo = useRecordContext(props);

  const { label, impersonateTokenUrl } = useMemo(() => {
    let { impersonateTokenUrl } = record;
    let label = 'Impersonate Portal Site';
    switch (host) {
      case 'cn':
        impersonateTokenUrl = record.impersonateTokenUrlForCN;
        label = 'Impersonate Portal Site (CN)';
        break;
      case 'union':
        impersonateTokenUrl = record.impersonateTokenUrlForUnion;
        label = 'Impersonate Portal Site (UNION)';
        break;
      default:
        break;
    }

    return { label, impersonateTokenUrl };
  }, [host, record]);

  const handleClick = () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      'The one time token will login as target user and really dangerous, continue?',
    );
    if (!isConfirmed) {
      return;
    }

    window.open(impersonateTokenUrl, '_blank', 'noopener,noreferrer');
  };

  return record ? (
    <Button component={Link} label={label} onClick={handleClick}>
      <IconOpenInNew />
    </Button>
  ) : null;
};
