import { useCallback } from 'react';
import {
  BooleanField,
  Button,
  CloneButton,
  DataProvider,
  Datagrid,
  EditButton,
  FunctionField,
  Identifier,
  TextField,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import { EventInjectedScriptVersion } from '../../types/event-injected-script-version';
import { ClipboardTextField } from '../common/ClipboardTextField';
import { DateTimeField } from '../common/DateTimeField';

interface CustomDataProvider extends DataProvider {
  activateInjectedScriptVersion: (versionId: Identifier) => Promise<void>;
  deactivateInjectedScriptVersion: (versionId: Identifier) => Promise<void>;
}

const ActivateButton = (props: { record: EventInjectedScriptVersion }) => {
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const handleClick = useCallback(async () => {
    await dataProvider.activateInjectedScriptVersion(props.record.id);
    refresh();
  }, [dataProvider, props.record.id, refresh]);

  return <Button label="Activate" onClick={handleClick} />;
};

const DeactivateButton = (props: { record: EventInjectedScriptVersion }) => {
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const handleClick = useCallback(async () => {
    await dataProvider.deactivateInjectedScriptVersion(props.record.id);
    refresh();
  }, [dataProvider, props.record.id, refresh]);

  return <Button label="Deactivate" onClick={handleClick} />;
};

export const EventInjectedScriptVersionDatagrid = () => {
  return (
    <Datagrid>
      <TextField source="id" />
      <DateTimeField hideLabel source="createdAt" />
      <DateTimeField hideLabel source="updatedAt" />
      <BooleanField sortable={false} source="isActive" />
      <ClipboardTextField isShowButtonOnly source="seedPreferredVersionUrl" />
      <EditButton />
      <CloneButton />
      <FunctionField<EventInjectedScriptVersion>
        render={(record) =>
          record && !record.isActive && <ActivateButton record={record} />
        }
      />
      <FunctionField<EventInjectedScriptVersion>
        render={(record) =>
          record?.isActive && <DeactivateButton record={record} />
        }
      />
    </Datagrid>
  );
};
