import { memo } from 'react';
import { ResourceComponentProps, Datagrid, TextField } from 'react-admin';
import { DateTimeField } from '../common/DateTimeField';

export const SubscriptionCreditSystemTransactionDatagrid: React.FC<ResourceComponentProps> =
  memo(() => {
    return (
      <Datagrid>
        <DateTimeField source="createdAt" />
        <TextField sortable={false} source="amount" label="pax" />
        <TextField sortable={false} source="notes" />
        <TextField sortable={false} source="action" />
        <DateTimeField source="refundedAt" sortable={false} />
      </Datagrid>
    );
  });
