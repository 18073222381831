import { pick } from 'lodash-es';
import React from 'react';
import {
  ShowButton,
  ResourceComponentProps,
  TextInput,
  TopToolbar,
  ListProps,
  useQuery,
  EditButton,
  Button,
} from 'react-admin';
import { Datagrid, DateField, List, TextField } from 'ra-ui-materialui';
import IconFileCopy from '@material-ui/icons/FileCopy';
import { Link } from 'react-router-dom';
import { DefaultListPagination } from '@/components/common/DefaultListPagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';
import { PricingPlan } from '@/types/pricing-plan';

const filters = [
  <TextInput
    key="id"
    alwaysOn
    label="ID"
    source="id"
    placeholder="Must be a valid UUID"
  />,
  <TextInput key="note" alwaysOn label="Internal Remarks" source="note" />,
];

const CloneFromEnterpriseButton: React.FC<ListProps> = () => {
  const { data, loading } = useQuery({
    type: 'getList',
    resource: 'pricing-plans',
    payload: {
      pagination: { page: 1, perPage: 25 },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        key: 'enterprise_sample',
      },
    },
  }) as { data?: PricingPlan[]; loading: boolean };

  const cloneRecord = pick(data?.[0], [
    'defaultEventFeatureConfig',
    'contentI18n',
    'organizationFeatureConfig',
  ]);

  return loading ? (
    <CircularProgress size="1.5rem" />
  ) : (
    <Button
      component={Link}
      startIcon={<IconFileCopy />}
      label="Clone from Enterprise Plan"
      to={{
        pathname: '/pricing-plans/create',
        state: {
          record: cloneRecord,
        },
      }}
    />
  );
};

const PricingPlanListActions: React.FC<ListProps> = () => {
  return (
    <TopToolbar>
      <CloneFromEnterpriseButton />
    </TopToolbar>
  );
};

export const PricingPlanList: React.ComponentType<
  ResourceComponentProps<Record<string, string>, any, any>
> = (props) => (
  <List
    {...props}
    actions={<PricingPlanListActions />}
    bulkActionButtons={false}
    perPage={50}
    pagination={<DefaultListPagination />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={filters}
    exporter={false}
  >
    <Datagrid>
      <ClipboardTextField source="id" />
      <TextField label="Name" source="contentI18n.name.en" />
      <TextField label="Internal remarks" source="note" sortable={false} />
      <DateField source="createdAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
