import { Button, useCreate, useNotify, useRefresh } from 'react-admin';
import { useCallback } from 'react';
import IconCreate from '@material-ui/icons/Create';

interface CreateImpersonateTokenButtonProps {
  userId: string;
}

export const CreateImpersonateTokenButton: React.FC<
  CreateImpersonateTokenButtonProps
> = ({ userId }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [create] = useCreate(
    'impersonate-tokens',
    {
      userId,
      expiredAt: new Date(Date.now() + 3600 * 1000 * 23).toISOString(),
    },
    {
      undoable: false,
      mutationMode: 'pessimistic',
      onFailure: (_) => {
        notify('Fail to create Impersonate Token!', 'error');
      },
      onSuccess: (_) => {
        refresh();
        notify('Impersonate Token created!');
      },
    },
  );

  const handleClick = useCallback(async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      'This will create impersonate token\nThe token will login as target user and really dangerous, continue?',
    );

    if (!isConfirmed) {
      return;
    }

    await create();
  }, [create]);

  return (
    <Button
      style={{
        whiteSpace: 'nowrap',
      }}
      startIcon={<IconCreate />}
      label="Create Impersonate Token"
      onClick={handleClick}
    />
  );
};
