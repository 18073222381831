import { TextFieldProps, useRecordContext } from 'react-admin';
import { styled, Typography } from '@material-ui/core';
import { TransformedReleaseControl } from '@/types';

export const StyledTypography = styled(Typography)({
  overflowWrap: 'break-word',
});
export const ReleaseControlNoteField: React.FC<TextFieldProps> = (props) => {
  const record = useRecordContext(props) as TransformedReleaseControl;

  return (
    <StyledTypography color="textSecondary">
      {record.releaseControl.note}
    </StyledTypography>
  );
};
