import React from 'react';
import { FieldProps } from 'react-admin';
import { makeStyles, Typography, InputLabel } from '@material-ui/core';

const useIdentifierStyles = makeStyles({
  title: {
    fontSize: '0.8rem',
    color: '#B3322B',
  },
  label: {
    fontSize: '0.8rem',
    marginBottom: '5px',
    marginTop: '10px',
  },
});

export const IdentifierLabelField: React.FC<FieldProps> = ({ label }) => {
  const classes = useIdentifierStyles();

  return (
    <InputLabel>
      <Typography className={classes.label}>{label}</Typography>
    </InputLabel>
  );
};
