import React from 'react';
import {
  DateField,
  Button,
  ListButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  TranslatableFields,
} from 'react-admin';
import { Link, useParams } from 'react-router-dom';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import IconEdit from '@material-ui/icons/Edit';
import { AVAILABLE_LOCALES } from '@/libs/translations';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';

const PricingPlanShowActions: React.FC<ShowProps> = ({ basePath }) => {
  const { id } = useParams<{ id: string }>();

  return (
    <TopToolbar>
      <Button
        component={Link}
        to={`/pricing-plans/${id}`}
        label="Edit"
        title="Edit"
      >
        <IconEdit />
      </Button>
      <ListButton basePath={basePath} label="Back" icon={<IconChevronLeft />} />
    </TopToolbar>
  );
};

export const PricingPlanShow: React.FC<ShowProps> = (props) => {
  return (
    <Show actions={<PricingPlanShowActions {...props} />} {...props}>
      <SimpleShowLayout>
        <ClipboardTextField label="ID" source="id" />
        <TranslatableFields locales={AVAILABLE_LOCALES}>
          <TextField label="Name" source="contentI18n.name" />
        </TranslatableFields>
        <TextField label="Internal remarks" source="note" emptyText="-" />
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show>
  );
};
