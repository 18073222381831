import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  BooleanField,
  Button,
  DataProvider,
  Edit,
  EditProps,
  FunctionField,
  Identifier,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { ClipboardTextField } from '../../components/common/ClipboardTextField';
import { DateTimeField } from '../../components/common/DateTimeField';
import { MonacoInput } from '../../components/common/MonacoInput';
import { TopToolbarWithBackButton } from '../../components/common/TopToolbarWithBackButton';
import { useFormStyles } from '../../hooks/useFormStyles';
import { EventInjectedScriptVersion } from '../../types/event-injected-script-version';

interface CustomDataProvider extends DataProvider {
  activateInjectedScriptVersion: (versionId: Identifier) => Promise<void>;
  deactivateInjectedScriptVersion: (versionId: Identifier) => Promise<void>;
}

const RecordSetter = (props: {
  setter: Dispatch<SetStateAction<EventInjectedScriptVersion | null>>;
}) => {
  const record = useRecordContext<EventInjectedScriptVersion>();
  useEffect(() => {
    props.setter(record);
  }, [props, record]);
  return null;
};

const ActivateButton = (props: { record: EventInjectedScriptVersion }) => {
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const handleClick = useCallback(async () => {
    await dataProvider.activateInjectedScriptVersion(props.record.id);
    refresh();
  }, [dataProvider, props.record.id, refresh]);

  return (
    <Button
      label="Activate"
      disabled={props.record.isActive}
      onClick={handleClick}
    />
  );
};

const DeactivateButton = (props: { record: EventInjectedScriptVersion }) => {
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const handleClick = useCallback(async () => {
    await dataProvider.deactivateInjectedScriptVersion(props.record.id);
    refresh();
  }, [dataProvider, props.record.id, refresh]);

  return (
    <Button
      label="Deactivate"
      disabled={!props.record.isActive}
      onClick={handleClick}
    />
  );
};

export const EventInjectedScriptVersionEdit = (props: EditProps) => {
  const classes = useFormStyles();
  const [record, setRecord] = useState<EventInjectedScriptVersion | null>(null);

  return (
    <>
      <RecordSetter setter={setRecord} />
      <Edit
        {...props}
        actions={
          <TopToolbarWithBackButton
            path={record ? `/events/${record.eventId}/show` : undefined}
          />
        }
      >
        <SimpleForm
          redirect={null}
          mutationMode="pessimistic"
          toolbar={
            <Toolbar>
              <SaveButton />
            </Toolbar>
          }
        >
          <TextInput
            disabled
            source="id"
            label="Event Injected Script Version ID"
            className={classes.formInput}
            validate={[required()]}
          />
          <TextInput
            disabled
            source="eventId"
            label="Event ID"
            className={classes.formInput}
            validate={[required()]}
          />
          <ClipboardTextField
            isShowButtonOnly
            source="seedPreferredVersionUrl"
          />
          <FunctionField<EventInjectedScriptVersion>
            label="Activate"
            render={(record) => record && <ActivateButton record={record} />}
          />
          <FunctionField<EventInjectedScriptVersion>
            label="Deactivate"
            render={(record) => record && <DeactivateButton record={record} />}
          />
          <BooleanField source="isActive" />
          <DateTimeField source="createdAt" />
          <MonacoInput width="75vw" height="50vh" source="injectedScript" />
        </SimpleForm>
      </Edit>
    </>
  );
};
