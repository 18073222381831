export interface Config {
  gcp: {
    oauthClientId: string;
  };
  api: {
    baseUrl: string;
  };
}

export function getConfig(): Config {
  return {
    gcp: {
      oauthClientId:
        process.env.REACT_APP_GCP_OAUTH_CLIENT_ID ?? '<gcp.oauthClientId>',
    },
    api: {
      baseUrl: process.env.REACT_APP_API_BASE_URL ?? '<api.baseUrl>',
    },
  };
}
