import { useCallback, useState } from 'react';
import { useDataProvider } from 'react-admin';
import ReactJson from 'react-json-view';
import IconSettings from '@material-ui/icons/Settings';
import { Button } from '@material-ui/core';

const EventFeatureConfigJsonViewer = (props: { eventId: string }) => {
  const [jsonData, setJsonData] = useState({});
  const [isShow, setIsShow] = useState(false);
  const { eventId } = props;
  const dataProvider = useDataProvider();
  const handleGetData = useCallback(async () => {
    const res = await dataProvider.getOne('feature-config/event', {
      id: eventId,
    });
    setJsonData(res);
  }, [dataProvider, eventId]);
  const handleGetAndShowData = async () => {
    try {
      await handleGetData();
      setIsShow(true);
    } catch (error) {
      console.error('error', error);
    }
  };

  if (isShow) {
    return <ReactJson src={jsonData} />;
  }

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      onClick={handleGetAndShowData}
    >
      <IconSettings fontSize="small" />
      &nbsp;Debug Event Feature Config
    </Button>
  );
};

const OrganizationFeatureConfigJsonViewer = (props: {
  organizationId: string;
}) => {
  const [jsonData, setJsonData] = useState({});
  const [isShow, setIsShow] = useState(false);
  const { organizationId } = props;
  const dataProvider = useDataProvider();
  const handleGetData = useCallback(async () => {
    const res = await dataProvider.getOne('feature-config/organization', {
      id: organizationId,
    });
    setJsonData(res);
  }, [dataProvider, organizationId]);
  const handleGetAndShowData = async () => {
    try {
      await handleGetData();
      setIsShow(true);
    } catch (error) {
      console.error('error', error);
    }
  };

  if (isShow) {
    return <ReactJson src={jsonData} />;
  }

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      onClick={handleGetAndShowData}
    >
      <IconSettings fontSize="small" />
      &nbsp;Debug Organization Feature Config
    </Button>
  );
};

export { EventFeatureConfigJsonViewer, OrganizationFeatureConfigJsonViewer };
