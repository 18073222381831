import { makeStyles } from '@material-ui/core';

export const useFormStyles = makeStyles((theme) => ({
  formInput: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      width: '65%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%',
    },
  },
}));
