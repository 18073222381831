import { ComponentType, useCallback } from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { CustomDomainPathNameTextInput } from '../../components/common/CustomDomainPathNameTextInput';
import { useHistory } from 'react-router-dom';
import { CustomDomainPathMapping } from '@/types';

export const CustomDomainPathMappingCreate: ComponentType = (props) => {
  const history = useHistory();

  const onSuceess = useCallback(
    (result: { data: CustomDomainPathMapping }) => {
      const url = `/custom-domains/${result.data.customDomainId}/show`;
      history.push(url);
    },
    [history],
  );

  return (
    <Create onSuccess={onSuceess} {...props}>
      <SimpleForm>
        <TextInput
          fullWidth
          disabled
          source="customDomainId"
          validate={[required()]}
        />
        <TextInput fullWidth source="eventId" validate={[required()]} />
        <CustomDomainPathNameTextInput fullWidth source="pathName" />
      </SimpleForm>
    </Create>
  );
};
