import { get } from 'lodash-es';
import { BooleanInput } from 'react-admin';

import { OVERRIDE_FIELD_NAME, FeatureConfigInputRecord } from './types';

export const InputWithEnabledToggle: React.FC<{
  record?: FeatureConfigInputRecord;
  isDisabled?: boolean;
  fieldName: string;
}> = ({ record, isDisabled, fieldName }) => {
  const source = `${OVERRIDE_FIELD_NAME}.${fieldName}`;

  return (
    <BooleanInput
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      initialValue={get(record, source) ?? false}
      source={source}
      disabled={isDisabled}
      label="Override: "
    />
  );
};
