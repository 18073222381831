import React from 'react';
import {
  Button,
  ListButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  BooleanField,
} from 'react-admin';
import { Link, useParams } from 'react-router-dom';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import IconEdit from '@material-ui/icons/Edit';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';
import { DateTimeField } from '@/components/common/DateTimeField';

import { DeletePublicApiTokenButton } from '@/components/public-api-token/DeleteImpersonateTokenButton';

const PublicApiTokenShowActions: React.FC<ShowProps> = (props) => {
  const { basePath } = props;
  const { id } = useParams<{ id: string }>();
  return (
    <TopToolbar>
      <>
        <DeletePublicApiTokenButton publicApiTokenId={id} />
        <Button
          component={Link}
          to={`/public-api-tokens/${id}`}
          label="Edit"
          title="Edit"
        >
          <IconEdit />
        </Button>
      </>

      <ListButton basePath={basePath} label="Back" icon={<IconChevronLeft />} />
    </TopToolbar>
  );
};

export const publicApiTokenShow: React.FC<ShowProps> = (props) => {
  return (
    <Show actions={<PublicApiTokenShowActions {...props} />} {...props}>
      <SimpleShowLayout>
        <ClipboardTextField label="Token Id" source="id" />
        <ClipboardTextField label="Token" source="token" />
        <BooleanField key="isValid" fullWidth source="isValid" />
        <BooleanField key="isEnabled" fullWidth source="isEnabled" />

        <br />
        <TextField source="userId" label="User Id" />
        <TextField source="user.name" label="User Name" />
        <TextField source="user.email" label="User Email" />

        <br />
        <DateTimeField source="createdAt" />

        <br />
        <DateTimeField source="expiredAt" />

        <br />
        <DateTimeField source="deletedAt" />

        <br />
        <TextField label="Internal remarks" source="note" emptyText="-" />
      </SimpleShowLayout>
    </Show>
  );
};
