import { get } from 'lodash-es';
import { styled } from '@material-ui/core';
import { useMemo } from 'react';
import { useFormState } from 'react-final-form';

import { InputWithEnabledToggle } from './InputWithEnabledToggle';
import { OVERRIDE_FIELD_NAME, FeatureConfigInputRecord } from './types';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  TextInput,
} from 'react-admin';
import { JsonInput } from './JsonInput';

const OverrideInputContainer = styled('div')({
  display: 'flex',
});

type ComponentType<T> = T extends
  | typeof BooleanInput
  | typeof TextInput
  | typeof NumberInput
  | typeof AutocompleteInput
  | typeof AutocompleteArrayInput
  | typeof JsonInput
  ? T
  : never;

export function constructInputComponent<T>(Component: ComponentType<T>) {
  const CastedComponent = Component as React.ComponentType<any>;

  const ConfigInput: React.FC<
    {
      record?: FeatureConfigInputRecord;
      hasOverrideToggle?: boolean;
      fieldName: string;
      label: string;
      source: string;
      isDisabled?: boolean;
      helperText?: string;
    } & React.ComponentProps<ComponentType<T>>
  > = ({
    record,
    hasOverrideToggle,
    fieldName,
    label,
    source,
    isDisabled,
    helperText,
    ...props
  }) => {
    const labelWithFeatureKey = helperText
      ? `(${fieldName}): ${helperText}`
      : fieldName;
    const nestedSource = `${source}.${fieldName}`;

    const { values } = useFormState();

    const isOverrideDisabled =
      get(values, `${OVERRIDE_FIELD_NAME}.${fieldName}`) === false;

    const input = useMemo(
      () => (
        <CastedComponent
          key={fieldName}
          fullWidth
          disabled={Boolean(isDisabled) || isOverrideDisabled}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          initialValue={get(record, nestedSource)}
          label={label}
          source={nestedSource}
          helperText={labelWithFeatureKey}
          {...props}
        />
      ),
      [
        fieldName,
        isDisabled,
        isOverrideDisabled,
        label,
        labelWithFeatureKey,
        nestedSource,
        record,
        props,
      ],
    );

    if (hasOverrideToggle) {
      return (
        <OverrideInputContainer>
          <InputWithEnabledToggle
            record={record}
            isDisabled={isDisabled}
            fieldName={fieldName}
          />
          {input}
        </OverrideInputContainer>
      );
    }

    return input;
  };

  return ConfigInput;
}
