import {
  Button,
  ButtonProps,
  useDelete,
  useNotify,
  useRefresh,
} from 'react-admin';
import IconDelete from '@material-ui/icons/Delete';
import { useCallback } from 'react';

type Props = ButtonProps & {
  label: string;
  eventId: string;
  pollingSetId: string | null;
};

export const WebinarSessionPollTruncateButton = (props: Props) => {
  const { label, pollingSetId, eventId, ...rest } = props;

  const [deleteOne, { loading }] = useDelete(
    'poll-questions',
    `event/${eventId}${pollingSetId ? `/polls/${pollingSetId}` : ''}`,
    {},
    {
      undoable: false,
      mutationMode: 'optimistic',
    },
  );
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = useCallback(async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      `${label}. This is very hard to undo this operation. Continue?`,
    );

    if (!isConfirmed) {
      return;
    }

    await deleteOne();

    refresh();
    notify('Polling data removed!');
  }, [deleteOne, label, notify, refresh]);

  return (
    <Button
      disabled={loading}
      style={{
        whiteSpace: 'nowrap',
      }}
      startIcon={<IconDelete />}
      label={label}
      onClick={handleClick}
      {...rest}
    />
  );
};
