import { memo } from 'react';
import {
  ResourceComponentProps,
  useRecordContext,
  Labeled,
  TextField,
  DeleteWithConfirmButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  stripeAccountDeleteButton: {
    marginTop: '27px',
    marginLeft: '15px',
  },
}));

export const StripeAccountField: React.FC<ResourceComponentProps> = memo(
  (props) => {
    const classes = useStyles();
    const record = useRecordContext<{
      id: string;
      stripeAccount: { id: string; accountId: string };
    }>(props);

    return record ? (
      <>
        <Labeled label="Stripe account">
          <TextField
            source="stripeAccount.accountId"
            emptyText="No Stripe account connected."
          />
        </Labeled>
        {record?.stripeAccount?.id ? (
          <DeleteWithConfirmButton
            redirect={false}
            basePath="stripe-accounts"
            resource="stripe-accounts"
            className={classes.stripeAccountDeleteButton}
            mutationMode="pessimistic"
            confirmTitle={`Delete Stripe account #${record.stripeAccount.accountId}`}
            record={{
              ...record,
              id: record.stripeAccount.id,
            }}
            undoable={false}
          />
        ) : null}
      </>
    ) : null;
  },
);
