import {
  DeleteWithConfirmButton,
  ResourceComponentProps,
  useRecordContext,
} from 'react-admin';

export const EventInternalInfoDeleteButton: React.FC<ResourceComponentProps> = (
  props,
) => {
  const record = useRecordContext<{
    id: string;
    eventId: string;
  }>(props);

  return record ? (
    <DeleteWithConfirmButton
      redirect={`/events/${record.eventId}/show`}
      basePath="events"
      resource="events"
      mutationMode="pessimistic"
      confirmTitle="Delete internal info"
      record={record}
      undoable={false}
    />
  ) : null;
};
