import * as Sentry from '@sentry/react';
import { AuthProvider, UserIdentity } from 'ra-core';
import { fetchUtils } from 'react-admin';
import { DateTime } from 'luxon';
import jwtDecode from 'jwt-decode';
import { getConfig } from '@/config';

interface AuthGoogleResponse {
  accessToken: string;
}

interface AccessTokenPayload {
  sub: string;
  username: string;
  email: string;
  exp: number;
  iat: number;
  picture?: string;
}

const config = getConfig();
export const accessTokenKey = 'accessToken';

export const authProvider: AuthProvider = {
  async login({ idToken }: { idToken: string }) {
    const options: fetchUtils.Options = {
      method: 'post',
      body: JSON.stringify({
        idToken,
      }),
    };

    const req = await fetchUtils.fetchJson(
      `${config.api.baseUrl}/auth`,
      options,
    );

    const res = req.json as AuthGoogleResponse;
    localStorage.setItem(accessTokenKey, res.accessToken);
  },
  async checkError(err: unknown): Promise<void> {
    Sentry.captureException(err);
  },
  async checkAuth(): Promise<void> {
    const accessToken = localStorage.getItem(accessTokenKey);
    if (!accessToken) {
      throw new Error();
    }

    const payload = jwtDecode<AccessTokenPayload>(accessToken);
    if (DateTime.now() >= DateTime.fromSeconds(payload.exp)) {
      throw new Error();
    }
  },
  async logout(): Promise<void> {
    localStorage.removeItem(accessTokenKey);
  },
  async getIdentity(): Promise<UserIdentity> {
    const accessToken = localStorage.getItem(accessTokenKey)!;
    const payload = jwtDecode<AccessTokenPayload>(accessToken);
    return {
      id: payload.email,
      fullName: payload.email,
      avatar: payload.picture,
    };
  },
  async getPermissions(): Promise<void> {
    // eslint-disable-next-line no-warning-comments
    // TODO: implement this function
  },
};
