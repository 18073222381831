import { FeatureConfigInput } from '@/components/common/FeatureConfigInput';
import { FormContainer } from '@/components/common/FormContainer';
import { IdentifierLabelField } from '@/components/common/IdentifierField';
import { OrganizationFeatureConfigInput } from '@/components/common/OrganizationFeatureConfigInput';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { useFormStyles } from '@/hooks/useFormStyles';
import { AVAILABLE_LOCALES } from '@/libs/translations';
import { pick } from 'lodash-es';
import React from 'react';
import {
  Create,
  CreateProps,
  Record as ReactAdminRecord,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TranslatableInputs,
} from 'react-admin';
import { PricingPlanDetails, pricingPlanFeatureConfigKeys } from '../../types';

// Always enable the save button
const PricingPlanCreateToolbar: React.FC = ({ ...props }) => (
  <Toolbar>
    <SaveButton {...props} />
  </Toolbar>
);

function transformSubmitData(data: ReactAdminRecord) {
  const result = data as PricingPlanDetails;
  result.defaultEventFeatureConfig = pick(
    result.defaultEventFeatureConfig,
    pricingPlanFeatureConfigKeys,
  );

  return result;
}

export const PricingPlanCreate: React.FC<CreateProps> = (props) => {
  const classes = useFormStyles();

  return (
    <Create
      resource="pricing-plans"
      title="Create Pricing Plan"
      actions={<TopToolbarWithBackButton />}
      transform={transformSubmitData}
      {...props}
    >
      <SimpleForm
        toolbar={<PricingPlanCreateToolbar />}
        component={FormContainer}
        redirect="show"
      >
        <TranslatableInputs locales={AVAILABLE_LOCALES}>
          <TextInput label="Name" source="contentI18n.name" />
        </TranslatableInputs>
        <IdentifierLabelField label="Organization Feature Config" />
        <OrganizationFeatureConfigInput source="organizationFeatureConfig" />
        <IdentifierLabelField label="Feature Configs" />
        <FeatureConfigInput
          mode="pricing-plan"
          source="defaultEventFeatureConfig"
        />
        <TextInput
          className={classes.formInput}
          label="Internal remarks"
          source="note"
        />
      </SimpleForm>
    </Create>
  );
};
