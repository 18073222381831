import {
  ResourceComponentProps,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
  useRecordContext,
  Button,
} from 'react-admin';
import { User } from '@/types';
import IconCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';

export const UserOrganizationDatagrid: React.FC<ResourceComponentProps> = (
  props,
) => {
  const record = useRecordContext<User>(props);
  return (
    <>
      <Button
        style={{
          whiteSpace: 'nowrap',
        }}
        component={Link}
        to={`/users/${record.id}/organization/create`}
        startIcon={<IconCreate />}
        label="Create Organization"
      />
      <Datagrid width="100%">
        <TextField source="name" sortable={false} />
        <TextField source="releaseStage" />
        <DateField source="createdAt" />
        <ShowButton />
      </Datagrid>
    </>
  );
};
