import Papa from 'papaparse';

function addBomToCsvString(params: { csvString: string }): string {
  const BOM = '\uFEFF';
  return BOM + params.csvString;
}

function transformCsvStringToObjectURLWithBom(params: {
  csvString: string;
}): string {
  const blob = new Blob([addBomToCsvString({ csvString: params.csvString })], {
    type: 'text/csv;charset=utf-8',
  });
  return URL.createObjectURL(blob);
}

function downloadFile(params: {
  href: string;
  filename: string;
  isNewTab?: boolean;
}): void {
  const link = document.createElement('a');
  link.download = params.filename;
  link.href = params.href;
  if (params.isNewTab) {
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
  }

  link.click();
}

export function downloadCsvFile(
  records: Array<Record<string, string>>,
  filename: string,
) {
  if (records.length === 0) return;

  const csvHeaders = Object.keys(records[0]!);

  const csvString = Papa.unparse(records, {
    columns: csvHeaders,
  });
  const url = transformCsvStringToObjectURLWithBom({ csvString });
  downloadFile({
    href: url,
    filename,
  });
}
