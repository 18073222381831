import React, { memo } from 'react';
import {
  EditProps,
  SaveButton,
  ToolbarProps,
  Toolbar,
  SimpleForm,
  Edit,
  TextField,
  TextInput,
  DateInput,
  Record as ReactAdminRecord,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { FormContainer } from '@/components/common/FormContainer';
import { PublicApiTokenWithUserInfo } from '@/types';
import { pick } from 'lodash-es';

const PublicApiTokenEditToolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

function transformSubmitData(data: ReactAdminRecord) {
  return {
    id: data.id,
    ...pick(data as PublicApiTokenWithUserInfo, ['expiredAt', 'note']),
  };
}

export const PublicApiTokenEdit: React.FC<EditProps> = memo((props) => {
  const { id } = useParams<{
    id: string;
  }>();

  return (
    <Edit
      resource="public-api-token"
      title={`Edit Public Api Token #${id}`}
      mutationMode="optimistic"
      transform={transformSubmitData}
      actions={<TopToolbarWithBackButton />}
      {...props}
    >
      <SimpleForm
        toolbar={<PublicApiTokenEditToolbar />}
        component={FormContainer}
        redirect="show"
      >
        <TextField label="TokenId" source="id" />
        <DateInput source="expiredAt" />
        <TextInput label="Internal remarks" source="note" />
      </SimpleForm>
    </Edit>
  );
});
