import { DateTimeField } from '@/components/common/DateTimeField';
import { IdentifierLabelField } from '@/components/common/IdentifierField';
import { TeamMemberCreateButton } from '@/components/common/buttons';
import { EventInjectedScriptVersionDatagrid } from '@/components/event-injected-script-version/EventInjectedScriptVersionDatagrid';
import { EventBoothDatagrid } from '@/components/event/EventBoothDatagrid';
import { EventChatExportButton } from '@/components/event/EventChatExportButton';
import { EventEditFeatureConfigButton } from '@/components/event/EventEditFeatureConfigButton';
import { EventManagerDatagrid } from '@/components/event/EventManagerDatagrid';
import { EventUrlButton } from '@/components/event/EventUrlButton';
import { EventWebinarSessionDatagrid } from '@/components/event/EventWebinarSessionDatagrid';
import { StreamChatTruncateButton } from '@/components/event/StreamChatTruncateButton';
import { WebinarSessionPollTruncateButton } from '@/components/event/WebinarSessionPollTruncateButton';
import { WebinarSessionQuestionTruncateButton } from '@/components/event/WebinarSessionQuestionTruncateButton';
import { EventFeatureConfigJsonViewer } from '@/components/feature-config-debugger';
import { EventImpersonateTokenDatagrid } from '@/components/impersonate-token/EventImpersonateTokenDatagrid';
import { useToggleReminderEmail } from '@/hooks/event/useToggleReminderEmail';
import { EventDetails } from '@/types';
import { FormControlLabel, Switch } from '@material-ui/core';
import IconAdd from '@material-ui/icons/Add';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import IconEdit from '@material-ui/icons/Edit';
import IconFindInPage from '@material-ui/icons/FindInPage';
import React, { memo, useCallback } from 'react';
import {
  Button,
  FieldProps,
  FunctionField,
  Identifier,
  Link,
  Loading,
  Pagination,
  Record,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  linkToRecord,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { useParams } from 'react-router-dom';

const EventShowActions: React.FC<ShowProps> = () => {
  return (
    <TopToolbar>
      <EventUrlButton />
      <EventInternalInfoActionsButton />
      <Button component={Link} to="/events" label="Back" title="Back">
        <IconChevronLeft />
      </Button>
    </TopToolbar>
  );
};

const EventInternalInfoActionsButton: React.FC<FieldProps> = memo((props) => {
  const record = useRecordContext(props);
  return record?.id ? <EventInternalInfoShowButton {...props} /> : null;
});

const EventInternalInfoShowButton: React.FC<FieldProps> = memo((props) => {
  const record = useRecordContext(props);
  const link = record?.eventInternalInfo
    ? linkToRecord(`/events/${record?.id}`, 'internal-info')
    : `/events/${record.id}/internal-info/create`;

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Button label="Internal Info">
        <IconFindInPage />
      </Button>
    </Link>
  );
});

const NameBadgeWrapper: React.FC<FieldProps> = (props) => {
  const record = useRecordContext<{
    id: Identifier;
    nameBadgeTemplate?: {
      id: string;
      name: string;
    };
  }>(props);

  const nameBadgeTemplate = record?.nameBadgeTemplate;

  const link = nameBadgeTemplate
    ? `/events/${record.id}/name-badge-custom-template/${nameBadgeTemplate?.id}/edit`
    : `/events/${record.id}/name-badge-custom-template/create`;

  const label = nameBadgeTemplate?.id
    ? `Template Name: ${nameBadgeTemplate.name}`
    : 'Create Name Badge Custom Template';

  return (
    <>
      <IdentifierLabelField label="Custom Name Badge Template" />
      <Button
        component={Link}
        to={link}
        label={label}
        title="NameBadge Custom Template"
      >
        {nameBadgeTemplate?.id ? <IconEdit /> : <IconAdd />}
      </Button>
    </>
  );
};

const InjectedScriptWrapper: React.FC<FieldProps> = () => {
  const record = useRecordContext<{
    id: Identifier;
  }>();

  return (
    <>
      <IdentifierLabelField label="Injected Script Versions" />
      <Button
        component={Link}
        to={{
          pathname: '/event-injected-script-versions/create',
          state: {
            record: {
              eventId: record.id,
            },
          },
        }}
        label="Create Injected Script"
      >
        <IconAdd />
      </Button>
      <ReferenceManyField
        label="Injected Script Versions"
        reference="event-injected-script-versions"
        target="eventId"
        pagination={<Pagination />}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <EventInjectedScriptVersionDatagrid />
      </ReferenceManyField>
    </>
  );
};

const LeadFinderWrapper: React.FC<FieldProps> = (props) => {
  const record = useRecordContext<{
    id: Identifier;
  }>(props);

  return (
    <>
      <IdentifierLabelField label="Lead Finder" />
      <Link
        to={`/events/${record.id}/lead-finder/copy-leads`}
        style={{ textDecoration: 'none' }}
      >
        <Button label="Copy Lead Finder Leads" title="Lead Finder">
          <IconEdit />
        </Button>
      </Link>
    </>
  );
};

const EventEditButton: React.FC<FieldProps> = (props) => {
  const record = useRecordContext(props);

  return record ? (
    <Button
      component={Link}
      to={`/events/${record.id}/edit`}
      label="Edit"
      title="Edit"
    >
      <IconEdit />
    </Button>
  ) : null;
};

const StreamChatWrapper: React.FC<FieldProps> = memo((props) => {
  const record = useRecordContext(props);
  const { loading } = useGetOne<EventDetails>('events', record.id);

  if (loading) return <Loading />;

  return record ? (
    <>
      <IdentifierLabelField label="Stream chat" />
      <StreamChatTruncateButton
        resource="events"
        label="Truncate public chat"
        channelType="public-chat"
      />
      <br />
      <StreamChatTruncateButton
        resource="events"
        label="Truncate all booth chat"
        channelType="booth-chat"
      />
      <br />
      <EventChatExportButton />
    </>
  ) : null;
});

const ReminderEmailWrapper: React.FC<FieldProps> = memo((props) => {
  const record = useRecordContext<{
    id: Identifier;
    isPublished?: boolean;
    isReminderCampaignsInactive?: {
      oneHour: boolean;
      oneDay: boolean;
    };
  }>(props);

  const oneHourToggle = useToggleReminderEmail({
    type: 'oneHour',
    record,
  });

  const oneDayToggle = useToggleReminderEmail({
    type: 'oneDay',
    record,
  });

  if (!record) {
    return null;
  }

  return (
    <>
      <IdentifierLabelField label="Reminder Emails" />
      <FormControlLabel
        control={<Switch checked={oneHourToggle.isActive} />}
        label="1hr"
        onChange={oneHourToggle.action}
      />

      <FormControlLabel
        control={<Switch checked={oneDayToggle.isActive} />}
        label="24hr"
        onChange={oneDayToggle.action}
      />
    </>
  );
});

const EventAttendeesWrapper: React.FC<FieldProps> = memo((props) => {
  const record = useRecordContext(props);

  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading }] = useUpdate();

  const handleClientGenerateAllMagicTokens = useCallback(async () => {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        'Are you sure you want to generate all magic tokens for this event attendees?',
      )
    ) {
      return;
    }

    await update('events', `${record.id}/generate-all-attendees-magic-tokens`);

    if (!loading) {
      refresh();
      notify('All this event attendees magic tokens has been generated');
    }
  }, [record, update, loading, notify, refresh]);

  if (!record) {
    return null;
  }

  return (
    <>
      <IdentifierLabelField label="Attendees" />
      <Button
        label="Generate all magic links"
        onClick={handleClientGenerateAllMagicTokens}
      >
        <IconEdit />
      </Button>
    </>
  );
});

export const EventShow: React.FC<ShowProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const { data: eventDetails } = useGetOne<EventDetails>('events', id);
  return (
    <Show actions={<EventShowActions {...props} />} {...props}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <TextField source="name" />
        <ReferenceField
          label="Organization"
          source="organizationId"
          reference="organizations"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceManyField
          reference="event-managers"
          target="eventId"
          label="Managers"
        >
          <EventManagerDatagrid />
        </ReferenceManyField>
        <TeamMemberCreateButton resource="events" />
        <StreamChatWrapper />
        <ReferenceManyField
          reference="webinar-sessions"
          target="eventId"
          label="Webinar Sessions"
          pagination={<Pagination />}
        >
          <EventWebinarSessionDatagrid />
        </ReferenceManyField>
        <FunctionField
          addLabel={false}
          render={(record?: Record) =>
            record ? (
              <WebinarSessionQuestionTruncateButton
                label="Truncate all qna"
                eventId={record.id as string}
                webinarSessionId={null}
              />
            ) : null
          }
        />
        <FunctionField
          addLabel={false}
          render={(record?: Record) =>
            record ? (
              <WebinarSessionPollTruncateButton
                label="Truncate all polling"
                eventId={record.id as string}
                pollingSetId={null}
              />
            ) : null
          }
        />
        <ReminderEmailWrapper />
        <EventAttendeesWrapper />
        <NameBadgeWrapper />
        <InjectedScriptWrapper />
        <LeadFinderWrapper />
        <ReferenceManyField
          reference="booths"
          target="eventId"
          label="Booths"
          pagination={<Pagination />}
        >
          <EventBoothDatagrid />
        </ReferenceManyField>

        <ReferenceManyField
          reference="impersonate-tokens/event"
          label="Impersonate Tokens"
          target="eventId"
          sort={{ field: 'createdAt', order: 'DESC' }}
          pagination={<Pagination />}
        >
          <EventImpersonateTokenDatagrid />
        </ReferenceManyField>

        <DateTimeField source="createdAt" />
        <DateTimeField source="startsAt" />
        <DateTimeField source="endsAt" />
        <EventEditButton />

        <br />
        <IdentifierLabelField label="Per Event Feature Config Overrides (! Only for urgent / debug use ! Please use subscription config if possible !)" />
        <EventEditFeatureConfigButton />
        <IdentifierLabelField label="Feature Config Debugger" />
        {eventDetails && (
          <EventFeatureConfigJsonViewer eventId={eventDetails.id} />
        )}
      </SimpleShowLayout>
    </Show>
  );
};
