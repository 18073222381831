import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { useFormStyles } from '@/hooks/useFormStyles';
import React, { memo } from 'react';
import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  required,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { FormContainer } from '@/components/common/FormContainer';

export const UserOrganizationCreate: React.FC<CreateProps> = memo((props) => {
  const classes = useFormStyles();

  const { userId } = useParams<{ userId: string }>();
  const basePath = `users/${userId}`;

  return (
    <Create
      title={`Create Organization #${userId}`}
      resource="organizations"
      basePath="organizations"
      actions={<TopToolbarWithBackButton path={`/${basePath}/show`} />}
      {...props}
    >
      <SimpleForm redirect={`/${basePath}/show`} component={FormContainer}>
        <TextInput
          disabled
          source="userId"
          label="User ID"
          defaultValue={userId}
          className={classes.formInput}
          min={0}
          validate={[required()]}
        />
        <TextInput
          source="name"
          label="Organization Name"
          className={classes.formInput}
          min={0}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
});
