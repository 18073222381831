import { ComponentType } from 'react';
import { List, TextInput } from 'react-admin';
import { DefaultListPagination } from '../../components/common/DefaultListPagination';
import CustomDomainDatagrid from './CustomDomainDatagrid';

const filters = [
  <TextInput
    key="organizationId"
    alwaysOn
    label="Organization ID"
    source="organizationId"
    placeholder="Must be a valid UUID"
  />,
  <TextInput key="domain" alwaysOn label="Domain" source="domain" />,
];

export const CustomDomainList: ComponentType = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    perPage={50}
    pagination={<DefaultListPagination />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={filters}
    exporter={false}
  >
    <CustomDomainDatagrid />
  </List>
);
