import React, { memo, useMemo, useCallback } from 'react';
import {
  EditProps,
  SaveButton,
  SimpleForm,
  Loading,
  Toolbar,
  useGetOne,
  TextField,
  Title,
  Button,
  TopToolbar,
  useNotify,
  useUpdate,
} from 'react-admin';
import { Card, makeStyles } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import { EventDetails, eventFeatureConfigKeys } from '@/types';
import { IdentifierLabelField } from '@/components/common/IdentifierField';
import { ApplyPricingPlan } from '@/components/event/ApplyPricingPlan';
import {
  FeatureConfigInput,
  OVERRIDE_FIELD_NAME,
} from '@/components/common/FeatureConfigInput';
import { FormContainer } from '@/components/common/FormContainer';
import { useFormStyles } from '@/hooks/useFormStyles';
import { RemoveOverrideButton } from '@/components/event/RemoveOverrideButton';
import { mapValues, mergeWith, omit, pick } from 'lodash-es';

const useStyles = makeStyles((theme) => ({
  applyPricingPlanContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '-40px',
      marginLeft: '-15px',
    },
    marginBottom: '-10px',
    flex: 1,
  },
}));

export const getCombinedFeatureConfigOverride = (params: {
  updateValues: {
    resolvedFeatureConfig: Record<string, unknown>;
    [OVERRIDE_FIELD_NAME]: Record<string, boolean>;
  };
  currValues: Record<string, unknown>;
}): Record<string, unknown> => {
  return omit(
    mergeWith(
      params.currValues,
      params.updateValues.resolvedFeatureConfig,
      (_, inputValue: unknown, key: string) => {
        // keys only exist on currValues side will be autometically merged
        if (params.updateValues[OVERRIDE_FIELD_NAME][key] === true)
          return inputValue;
        return undefined;
      },
    ),
    Object.entries(params.updateValues[OVERRIDE_FIELD_NAME])
      .filter(([_, overrided]) => !overrided)
      .map(([featFlag]) => featFlag),
  );

  // To learn more about what the above code is addressing, please refer to the test cases
};

export const useUpdateFeatureConfigOverride = (params: {
  eventId: string;
  currOverrideValues?: Record<string, unknown>;
}) => {
  const notify = useNotify();
  const [update, { loading }] = useUpdate('events');

  return useCallback(
    async (values) => {
      if (!params.currOverrideValues) {
        // eslint-disable-next-line no-alert
        window.alert(
          'Not able to find the current override values. Please try again.',
        );
        return;
      }

      // eslint-disable-next-line no-alert
      const isConfirmed = window.confirm(
        "This will override subscription's features, continue?",
      );

      if (!isConfirmed) {
        return;
      }

      const castedValues = values as {
        resolvedFeatureConfig: Record<string, unknown>;
        [OVERRIDE_FIELD_NAME]: Record<string, boolean>;
      };

      if (
        !Array.isArray(
          castedValues.resolvedFeatureConfig.liveStreamingDisabledTicketIds,
        )
      ) {
        castedValues.resolvedFeatureConfig.liveStreamingDisabledTicketIds =
          castedValues.resolvedFeatureConfig.liveStreamingDisabledTicketIds
            ? (
                castedValues.resolvedFeatureConfig
                  .liveStreamingDisabledTicketIds as string
              ).split(',')
            : [];
      }

      await update('events', `${params.eventId}/feature-config-override`, {
        featureConfigOverride: getCombinedFeatureConfigOverride({
          updateValues: castedValues,
          currValues: params.currOverrideValues,
        }),
      });

      if (!loading) {
        notify('Feature config updated');
      }
    },
    [loading, notify, params.eventId, update, params.currOverrideValues],
  );
};

export const EventFeatureConfigEdit: React.FC<EditProps> = memo(() => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data, loading } = useGetOne<EventDetails>('events', eventId);

  const handleSave = useUpdateFeatureConfigOverride({
    eventId,
    currOverrideValues: data?.featureConfigOverride,
  });
  const classes = useStyles();
  const formClasses = useFormStyles();
  const basePath = `/events/${eventId}/show`;

  const transformedData = useMemo(() => {
    if (!data) {
      return data;
    }

    data.featureConfigOverride = pick(
      data.featureConfigOverride,
      eventFeatureConfigKeys,
    );

    return {
      ...data,
      [OVERRIDE_FIELD_NAME]: mapValues(data.featureConfigOverride, () => true),
    };
  }, [data]);

  if (loading) return <Loading />;

  return (
    <>
      <TopToolbar>
        <SimpleForm
          toolbar={<div />}
          className={classes.applyPricingPlanContainer}
        >
          <ApplyPricingPlan eventId={eventId} />
        </SimpleForm>
        <RemoveOverrideButton eventId={eventId} />
        <Button component={Link} to={basePath} label="Back" title="Back">
          <IconChevronLeft />
        </Button>
      </TopToolbar>
      <Card>
        <Title title="Event Feature Config" />
        <SimpleForm
          warnWhenUnsavedChanges
          component={FormContainer}
          toolbar={
            <Toolbar>
              <SaveButton
                submitOnEnter
                label="ra.action.save"
                onSave={handleSave}
              />
            </Toolbar>
          }
        >
          <TextField
            className={formClasses.formInput}
            record={data}
            source="id"
          />
          <IdentifierLabelField label="Feature Config Overrides" />
          <FeatureConfigInput
            hasOverrideToggle
            source="resolvedFeatureConfig"
            record={transformedData}
            mode="event"
          />
        </SimpleForm>
      </Card>
    </>
  );
});
