import React from 'react';
import {
  Edit,
  SimpleForm,
  EditProps,
  Toolbar,
  SaveButton,
  Record as ReactAdminRecord,
  useGetOne,
  Loading,
  TextField,
  BooleanInput,
  SelectInput,
  TextInput,
  required,
} from 'react-admin';
import { FormContainer } from '@/components/common/FormContainer';
import { DefaultLandingSystem } from '@/types';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { useParams } from 'react-router-dom';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';

const UserEditToolbar: React.FC = ({ ...props }) => (
  <Toolbar>
    <SaveButton {...props} />
  </Toolbar>
);

export const UserEdit: React.FC<EditProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const { data: userData, loaded: isLoaded } = useGetOne('users', id);

  const transform = (
    data: ReactAdminRecord & {
      isEmsUser?: boolean;
      name?: string;
      defaultLandingSystem?: DefaultLandingSystem;
    },
  ) => {
    return {
      id: data.id,
      name: data.name,
      isEmsUser: data.isEmsUser,
      defaultLandingSystem: data.defaultLandingSystem
        ? data.defaultLandingSystem
        : undefined,
    };
  };

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <Edit
      undoable
      resource="pricing-plans"
      mutationMode="pessimistic"
      title="Edit Pricing Plan"
      transform={transform}
      actions={<TopToolbarWithBackButton />}
      {...props}
    >
      <SimpleForm
        toolbar={<UserEditToolbar {...props} />}
        component={FormContainer}
        redirect="show"
        record={userData}
      >
        <ClipboardTextField label="ID" source="id" />
        <TextInput key="name" source="name" validate={[required()]} />
        <TextField source="email" />
        <BooleanInput
          key="isEmsUser"
          fullWidth
          label="is legacy ems user"
          source="isEmsUser"
        />
        <SelectInput
          source="defaultLandingSystem"
          choices={[
            { id: DefaultLandingSystem.ESAAS, name: 'ESAAS' },
            { id: DefaultLandingSystem.EMS, name: 'EMS' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};
