import { TransformedReleaseControl } from '@/types';
import { BooleanFieldProps, useRecordContext, BooleanField } from 'react-admin';

const ReleaseControlStageBooleanField = (props: BooleanFieldProps) => {
  const record = useRecordContext(props) as TransformedReleaseControl;
  if (!record.isInitialized) {
    return <BooleanField style={{ color: 'red' }} {...props} />;
  }

  return <BooleanField {...props} />;
};

export { ReleaseControlStageBooleanField };
