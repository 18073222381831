import React, { memo } from 'react';
import {
  EditProps,
  SaveButton,
  ToolbarProps,
  Toolbar,
  useGetOne,
  required,
  TextInput,
  SimpleForm,
  useDataProvider,
  DataProvider,
  useNotify,
  useRefresh,
  Edit,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { FormContainer } from '@/components/common/FormContainer';
import { useFormStyles } from '@/hooks/useFormStyles';
import NameBadgeTemplateEditor from './components/NameBadgeTemplateEditor';
import { httpClient } from '@/App';
import { omitBy, isNil } from 'lodash-es';
import * as Sentry from '@sentry/react';
import {
  ElementStyle,
  NameBadgeTemplateLayoutConfig,
} from './components/NameBadgePreview';

export interface nameBadgeParams {
  eventId: string;
  templateId: string;
  values: {
    name: string;
    layoutConfig: NameBadgeTemplateLayoutConfig;
  };
}
interface CustomDataProvider extends DataProvider {
  updateNameBadge: (params: nameBadgeParams) => Promise<void>;
}

const NameBadgeCustomTemplateToolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export const updateNameBadge = async (params: nameBadgeParams) => {
  const { eventId, templateId, values } = params;
  const res = await httpClient(
    `${
      process.env.REACT_APP_API_BASE_URL ?? ''
    }/events/${eventId}/name-badge-custom-template/${templateId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(values),
    },
  );
  return {
    data: res.json as Record<string, unknown>,
  };
};

export const NameBadgeCustomTemplateEdit: React.FC<EditProps> = memo(() => {
  const classes = useFormStyles();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const { eventId, templateId } = useParams<{
    eventId: string;
    templateId: string;
  }>();

  const refresh = useRefresh();
  const notify = useNotify();

  const { data } = useGetOne('events', `${eventId}/name-badge-custom-template`);
  const basePath = `/events/${eventId}/show`;
  const handleOnSubmit = async (values: {
    name: string;
    layoutConfig: NameBadgeTemplateLayoutConfig;
  }) => {
    values.layoutConfig.elements.forEach((item) => {
      item.style = omitBy(item.style, isNil) as ElementStyle;
    });

    try {
      await dataProvider.updateNameBadge({
        eventId,
        templateId,
        values: {
          name: values.name,
          layoutConfig: values.layoutConfig,
        },
      });
      refresh();
      notify('Custom Badge Template Saved');
    } catch (err: unknown) {
      if (err instanceof Error) {
        notify(err.message);
      }

      Sentry.captureException(err);
    }
  };

  return (
    <Edit
      id={`${eventId}/name-badge-custom-template`}
      title={`Edit NameBadge Custom Template #${templateId}`}
      resource="events"
      basePath={basePath}
      actions={<TopToolbarWithBackButton path={basePath} />}
      mutationMode="optimistic"
    >
      <SimpleForm
        record={data}
        save={handleOnSubmit}
        toolbar={<NameBadgeCustomTemplateToolbar />}
        component={FormContainer}
        redirect={basePath}
      >
        <TextInput
          disabled
          source="eventId"
          label="Event ID"
          defaultValue={eventId}
          className={classes.formInput}
          min={0}
          validate={[required()]}
        />
        <TextInput
          disabled
          source="templateId"
          label="NameBadge Tempalte ID"
          defaultValue={templateId}
          className={classes.formInput}
          min={0}
          validate={[required()]}
        />
        <TextInput
          source="name"
          label="Badge Template Name"
          className={classes.formInput}
          min={0}
          validate={[required()]}
        />
        <NameBadgeTemplateEditor source="layoutConfig" />
      </SimpleForm>
    </Edit>
  );
});
