import React from 'react';
import {
  TextInput,
  ListProps,
  Button,
  linkToRecord,
  EditButton,
  EditButtonProps,
  useQuery,
  useGetOne,
  useRecordContext,
  useMutation,
  ButtonProps,
  useRefresh,
  useNotify,
  ShowButton,
} from 'react-admin';
import { Datagrid, List, TextField } from 'ra-ui-materialui';
import AddIcon from '@material-ui/icons/Add';
import { DefaultListPagination } from '@/components/common/DefaultListPagination';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';
import { Link, useParams } from 'react-router-dom';
import { PricingPlan } from '@/types/pricing-plan';
import { pick } from 'lodash-es';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OrganizationDetails, SubscriptionDetails } from '@/types';
import { DateTimeField } from '@/components/common/DateTimeField';

const IsActiveButton = (
  props: ButtonProps & {
    activeSubscriptionId?: SubscriptionDetails['id'];
  },
) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { resourceId: organizationId } = useParams<{ resourceId: string }>();
  const { id, note } = useRecordContext(props);
  const [mutate] = useMutation(
    {
      type: 'update',
      resource: `organizations/${organizationId}`,
      payload: {
        id: 'activate-subscription',
        data: {
          activeSubscriptionId: id,
        },
      },
    },
    {
      onSuccess: () => {
        refresh();
        notify(`${String(note ?? id)}: (${id}) activated`);
      },
    },
  );

  const isActive = id === props.activeSubscriptionId;

  return (
    <Button
      disabled={isActive}
      title="isActive"
      label={isActive ? 'active' : 'activate'}
      {...props}
      onClick={mutate}
    />
  );
};

const CreateSubscriptionButton: React.FC<
  ListProps & {
    organizationId: string;
  }
> = ({ organizationId }) => {
  const { data, loading } = useQuery(
    {
      type: 'getList',
      resource: 'pricing-plans',
      payload: {
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          key: 'enterprise_sample',
        },
      },
    },
    {
      onSuccess(res: { data?: PricingPlan[]; loading: boolean }) {
        if (res.data?.[0]?.contentI18n?.name) {
          for (const key of Object.keys(res.data[0].contentI18n.name)) {
            res.data[0].contentI18n.name[key] =
              'All In One Platform Subscription';
          }
        }

        return res;
      },
    },
  ) as { data?: PricingPlan[]; loading: boolean };

  const cloneRecord = pick(data?.[0], [
    'defaultEventFeatureConfig',
    'contentI18n',
    'organizationFeatureConfig',
  ]);

  return loading ? (
    <CircularProgress size="1.5rem" />
  ) : (
    <Button
      component={Link}
      startIcon={<AddIcon />}
      label="Create a Subscription"
      to={{
        pathname: `/organizations/${organizationId}/subscriptions/create`,
        state: {
          record: {
            organizationId,
            pricingPlan: cloneRecord,
          },
        },
      }}
    />
  );
};

const filters = [
  <TextInput
    key="id"
    alwaysOn
    label="ID"
    source="id"
    placeholder="Must be a valid UUID"
  />,
  <TextInput key="note" alwaysOn label="Internal Remarks" source="note" />,
];

const SubscriptionEditButton = (props: EditButtonProps) => {
  const { record } = props;

  return (
    <EditButton
      {...props}
      to={{
        pathname: record
          ? `${linkToRecord('/subscriptions', record.id, 'edit')}`
          : '',
      }}
    />
  );
};

const SubscriptionShowButton = (props: EditButtonProps) => {
  const { record } = props;

  return (
    <ShowButton
      {...props}
      to={{
        pathname: record
          ? `${linkToRecord('/subscriptions', record.id, 'show')}`
          : '',
      }}
    />
  );
};

export const SubscriptionList: React.FC<
  ListProps & {
    basePath: string;
    redirect: string;
    source: string;
  }
> = (props) => {
  const { resourceId: organizationId } = useParams<{ resourceId: string }>();

  const { data } = useGetOne<OrganizationDetails>(
    'organizations',
    organizationId,
  );

  return (
    <List
      {...props}
      filter={{
        organizationId,
      }}
      resource="subscriptions"
      bulkActionButtons={false}
      perPage={50}
      pagination={<DefaultListPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={filters}
      exporter={false}
      title={`${organizationId}'s subscriptions`}
      empty={<CreateSubscriptionButton organizationId={organizationId} />}
      actions={<CreateSubscriptionButton organizationId={organizationId} />}
    >
      <Datagrid>
        <IsActiveButton activeSubscriptionId={data?.activeSubscriptionId} />;
        <TextField
          label="name"
          source="pricingPlan.contentI18n.name.en"
          sortable={false}
        />
        <ClipboardTextField source="id" />
        <TextField label="Internal remarks" source="note" sortable={false} />
        <TextField
          label="contract Id"
          source="internalReferenceNo"
          sortable={false}
        />
        <DateTimeField label="startedAt" source="startedAt" sortable={false} />
        <DateTimeField label="expiresAt" source="expiresAt" sortable={false} />
        <TextField
          label="Attendee Pax(total Event)"
          source="attendeeCreditSystemBalance.totalCredits"
          sortable={false}
        />
        <SubscriptionShowButton />
        <SubscriptionEditButton />
      </Datagrid>
    </List>
  );
};
