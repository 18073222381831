import React from 'react';
import {
  Edit,
  SimpleForm,
  EditProps,
  SelectInput,
  Toolbar,
  SaveButton,
  TextField,
  Record as ReactAdminRecord,
  DateTimeInput,
  useQuery,
  Loading,
  TextInput,
} from 'react-admin';
import { FormContainer } from '@/components/common/FormContainer';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import {
  OrganizationDetails,
  SubscriptionDetails,
  releaseStages,
} from '@/types';
import { pick } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

const OrganizationEditToolbar: React.FC = ({ ...props }) => (
  <Toolbar>
    <span>
      <SaveButton {...props} />
    </span>
  </Toolbar>
);

function transformSubmitData(data: ReactAdminRecord) {
  return pick(data as OrganizationDetails, [
    'id',
    'name',
    'releaseStage',
    'paymentOverduesAt',
    'activeSubscriptionId',
  ]);
}

const releaseStageChoices = releaseStages.map((e) => {
  return {
    id: e,
    name: e,
  };
});

export const OrganizationEdit: React.FC<EditProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery({
    type: 'getList',
    resource: 'subscriptions',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        organizationId: id,
      },
    },
  }) as { data: SubscriptionDetails[]; loading: boolean };

  const timezoneName = DateTime.local().toFormat('ZZZZ');

  if (loading) {
    return <Loading />;
  }

  return (
    <Edit
      undoable
      resource="release-controls"
      mutationMode="pessimistic"
      title="Edit Release Control"
      transform={transformSubmitData}
      actions={<TopToolbarWithBackButton />}
      {...props}
    >
      <SimpleForm
        component={FormContainer}
        redirect="show"
        toolbar={<OrganizationEditToolbar {...props} />}
      >
        <TextField label="ID" source="id" />
        <TextInput label="Name" source="name" />
        <SelectInput source="releaseStage" choices={releaseStageChoices} />
        <DateTimeInput
          source="paymentOverduesAt"
          label="Account will be blocked at"
          helperText={`The timezone above is in ${timezoneName}`}
        />
        <SelectInput
          source="activeSubscriptionId"
          choices={data.map(({ id, note }) => ({
            id,
            name: `${note ?? id}(${id})`,
          }))}
        />
      </SimpleForm>
    </Edit>
  );
};
