import { get } from 'lodash-es';
import { Box, Button } from '@material-ui/core';
import {
  TextField,
  TextFieldProps,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconFileCopy from '@material-ui/icons/FileCopy';
import { useCallback, useMemo } from 'react';
import { IdentifierLabelField } from './IdentifierField';

export const ClipboardTextField: React.FC<
  TextFieldProps & {
    isShowButtonOnly?: boolean;
  }
> = (props) => {
  const record = useRecordContext(props);
  const copyValue = useMemo(
    () => get(record, props.source ?? '') as string,
    [record, props.source],
  );
  const notify = useNotify();

  const handleCopy = useCallback(() => {
    notify(`Copied ${copyValue}`);
  }, [copyValue, notify]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gridColumnGap="8px"
    >
      {props.label && <IdentifierLabelField label={props.label} />}
      {props.isShowButtonOnly ? null : <TextField {...props} />}

      <CopyToClipboard text={copyValue} onCopy={handleCopy}>
        <Button
          startIcon={<IconFileCopy />}
          color="secondary"
          style={{ marginLeft: 8 }}
        >
          Copy {props.source}
        </Button>
      </CopyToClipboard>
    </Box>
  );
};
