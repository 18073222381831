import { memo } from 'react';
import { constructFullEventUrl, eventRoutes } from '@/routes/eventRoutes';
import {
  Button,
  Link,
  ResourceComponentProps,
  useRecordContext,
} from 'react-admin';
import IconOpenInNew from '@material-ui/icons/OpenInNew';

export const EventUrlButton = memo((props: ResourceComponentProps) => {
  const record = useRecordContext(props);
  const url = record
    ? constructFullEventUrl(
        eventRoutes.eventPage({ eventId: record.id as string }),
      )
    : '';

  return record ? (
    <Button
      component={Link}
      label="Event Site"
      title="Event Site"
      onClick={() => window.open(url, '_blank')}
    >
      <IconOpenInNew />
    </Button>
  ) : null;
});
