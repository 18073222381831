import React, { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { Login, LoginComponent, useLogin, useNotify } from 'react-admin';
import { Box } from '@material-ui/core';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';

const LoginPage: LoginComponent = (props) => {
  const login = useLogin();
  const notify = useNotify();

  const handleGoogleLoginSuccess = useCallback(
    async (res: CredentialResponse) => {
      try {
        await login({ idToken: res.credential });
      } catch (err: unknown) {
        if (err instanceof Error) {
          notify(err.message);
        } else {
          notify('Failed to login');
        }

        Sentry.captureException(err);
      }
    },
    [login, notify],
  );

  const handleGoogleLoginError = () => {
    notify('Unknown error');
  };

  return (
    <Login {...props}>
      <Box display="flex" alignItems="center" justifyContent="center" p={1}>
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginError}
        />
      </Box>
    </Login>
  );
};

export default LoginPage;
