import { useCallback } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';

export const useApplyPricingPlanFeatures = (params: {
  eventId: string;
  pricingPlanId: string;
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading }] = useUpdate();

  return useCallback(async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      "This will override subscription's features, continue?",
    );

    if (!isConfirmed) {
      return;
    }

    await update(
      'events',
      `${params.eventId}/feature-config-override/apply-features`,
      {
        pricingPlanId: params.pricingPlanId,
      },
    );

    if (!loading) {
      refresh();
      notify('Pricing plan applied');
    }
  }, [loading, notify, params.eventId, params.pricingPlanId, refresh, update]);
};
