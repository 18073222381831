import { ComponentType } from 'react';
import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  Record,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  useRecordContext,
} from 'react-admin';
import { DateTimeField } from '../../components/common/DateTimeField';
import { WarningTypography } from '../../components/common/WarningTypography';

interface CustomDomainPathRecord extends Record {
  pathName: string;
}

const CreatePathButton = () => {
  const customDomain = useRecordContext();
  return (
    <CreateButton
      to={{
        pathname: '/custom-domain-path-mappings/create',
        state: { record: { customDomainId: customDomain.id } },
      }}
    />
  );
};

export const CustomDomainShow: ComponentType = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <WarningTypography>
        Custom Domain is for infra engineers only, please ensure the domain is
        set up correctly before using it.
      </WarningTypography>
      <TextField source="id" label="Custom Domain ID" />
      <TextField source="organizationId" label="Organization ID" />
      <TextField source="domain" label="Domain" />
      <UrlField source="defaultRedirectUrl" label="Default Redirect URL" />
      <DateTimeField source="createdAt" label="CreatedAt" />
      <ReferenceManyField
        label="paths"
        reference="custom-domain-path-mappings"
        target="customDomainId"
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid>
          <TextField sortable={false} source="id" label="Path ID" />
          <TextField sortable={false} source="eventId" label="Event ID" />
          <FunctionField<CustomDomainPathRecord>
            sortable={false}
            label="Path"
            render={(record) => record?.pathName}
          />
          <DateTimeField hideLabel source="createdAt" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      <CreatePathButton />
    </SimpleShowLayout>
  </Show>
);
