import { Button, useDelete, useNotify, useRefresh } from 'react-admin';
import { useCallback } from 'react';
import IconDelete from '@material-ui/icons/Delete';

interface RemoveOverrideButtonProps {
  eventId: string;
}

export const RemoveOverrideButton: React.FC<RemoveOverrideButtonProps> = ({
  eventId,
}) => {
  const [deleteOne] = useDelete(
    'events',
    `${eventId}/feature-config-override`,
    {},
    {
      undoable: false,
      mutationMode: 'pessimistic',
    },
  );
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = useCallback(async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      'This will remove all feature overrides, continue?',
    );

    if (!isConfirmed) {
      return;
    }

    await deleteOne();
    refresh();
    notify('Feature config overrides removed!');
  }, [deleteOne, refresh, notify]);

  return (
    <Button
      style={{
        whiteSpace: 'nowrap',
      }}
      startIcon={<IconDelete />}
      label="Remove Overrides"
      onClick={handleClick}
    />
  );
};
