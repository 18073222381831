import {
  ResourceComponentProps,
  Datagrid,
  TextField,
  useRecordContext,
  ShowButton,
  BooleanField,
} from 'react-admin';
import { CreatePublicApiTokenButton } from './CreatePublicApiTokenButton';
import { User } from '@/types';
import { ClipboardTextField } from '../common/ClipboardTextField';

export const UserPublicApiTokenDatagrid: React.FC<ResourceComponentProps> = (
  props,
) => {
  const record = useRecordContext<User>(props);
  return (
    <>
      <CreatePublicApiTokenButton userId={record.id} />
      <Datagrid>
        <TextField sortable={false} source="id" label="Token Id" />
        <BooleanField key="isValid" fullWidth source="isValid" />
        <ClipboardTextField sortable={false} source="token" />
        <ShowButton />
      </Datagrid>
    </>
  );
};
