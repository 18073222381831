import { Editor, EditorProps, Monaco } from '@monaco-editor/react';
import { InputProps, useInput } from 'react-admin';
import {
  thirdPartyExtraLibs,
  injectedScriptTemplateLanguage,
  injectedScriptTypes,
  injectedScriptTypesLanguage,
  injectedScriptTypesPath,
} from '../../libs/injected-script';
import { useCallback } from 'react';

export const MonacoInput = (
  props: InputProps &
    // eslint-disable-next-line @typescript-eslint/ban-types
    Omit<EditorProps, 'language' | 'theme' | 'options' | 'beforeMount'>,
) => {
  const { input } = useInput(props);

  const handleEditorWillMount = useCallback((monaco: Monaco) => {
    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: false,
      diagnosticCodesToIgnore: [80004],
    });
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES2015,
      allowNonTsExtensions: true,
      allowJs: true,
    });

    monaco.languages.typescript.javascriptDefaults.addExtraLib(
      injectedScriptTypes,
      injectedScriptTypesPath,
    );
    for (const thirdPartyExtraLib of thirdPartyExtraLibs) {
      monaco.languages.typescript.javascriptDefaults.addExtraLib(
        thirdPartyExtraLib.source,
        thirdPartyExtraLib.path,
      );
    }

    const uri = monaco.Uri.parse(injectedScriptTypesPath);
    const existingModel = monaco.editor.getModel(uri);
    if (existingModel) existingModel.dispose();

    monaco.editor.createModel(
      injectedScriptTypes,
      injectedScriptTypesLanguage,
      uri,
    );
  }, []);

  return (
    <Editor
      language={injectedScriptTemplateLanguage}
      theme="vs-dark"
      options={{
        selectOnLineNumbers: true,
        formatOnPaste: true,
        automaticLayout: true,
      }}
      width="75vw"
      height="50vh"
      beforeMount={handleEditorWillMount}
      // onChange={handleEditorChange}
      {...input}
    />
  );
};
