import {
  ResourceComponentProps,
  Datagrid,
  TextField,
  useRecordContext,
  ShowButton,
  BooleanField,
} from 'react-admin';
import { CreateImpersonateTokenButton } from './CreateImpersonateTokenButton';
import { User } from '@/types';

export const UserImpersonateTokenDatagrid: React.FC<ResourceComponentProps> = (
  props,
) => {
  const record = useRecordContext<User>(props);
  return (
    <>
      <CreateImpersonateTokenButton userId={record.id} />
      <Datagrid>
        <TextField sortable={false} source="id" label="Token Id" />
        <TextField
          source="createdByInternalUserEmail"
          label="Created By Email"
        />
        <BooleanField key="isValid" fullWidth source="isValid" />
        <ShowButton />
      </Datagrid>
    </>
  );
};
