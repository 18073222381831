import React, { useMemo } from 'react';
import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  SelectInput,
  required,
  DateTimeInput,
  TranslatableInputs,
  SaveButton,
  SaveButtonProps,
  Toolbar,
  useGetOne,
  NumberInput,
} from 'react-admin';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import {
  OrganizationDetails,
  PricingPlanDetails,
  SubscriptionDetails,
  pricingPlanFeatureConfigKeys,
} from '@/types';
import { useFormStyles } from '@/hooks/useFormStyles';
import { FormContainer } from '@/components/common/FormContainer';
import { useParams } from 'react-router-dom';
import { FeatureConfigInput } from '@/components/common/FeatureConfigInput';
import { IdentifierLabelField } from '@/components/common/IdentifierField';
import { OrganizationFeatureConfigInput } from '@/components/common/OrganizationFeatureConfigInput';
import { AVAILABLE_LOCALES } from '@/libs/translations';
import { pick } from 'lodash-es';

function redirect(basePath: string, id: string, data: SubscriptionDetails) {
  return `/organizations/${data.organizationId}/subscriptions`;
}

function transformSubmitData(params: {
  data: Record<string, unknown>;
  isSaveAndActivateSubscription?: boolean;
}) {
  const { data, isSaveAndActivateSubscription = false } = params;
  const result = data.pricingPlan as PricingPlanDetails;
  result.defaultEventFeatureConfig = pick(
    result.defaultEventFeatureConfig,
    pricingPlanFeatureConfigKeys,
  );

  return {
    ...data,
    pricingPlan: result,
    isActive: isSaveAndActivateSubscription,
  };
}

const SubscriptionCreateToolbar: React.FC<
  SaveButtonProps & {
    activeSubscriptionId?: string;
  }
> = (props) => {
  return (
    <Toolbar>
      {Boolean(props.activeSubscriptionId) && (
        <SaveButton
          {...props}
          label="Create"
          style={{
            marginRight: '20px',
          }}
          transform={(record: Record<string, unknown>) =>
            transformSubmitData({
              data: record,
            })
          }
        />
      )}
      <SaveButton
        {...props}
        label="Create & Activate"
        transform={(record: Record<string, unknown>) =>
          transformSubmitData({
            data: record,
            isSaveAndActivateSubscription: true,
          })
        }
      />
    </Toolbar>
  );
};

export const SubscriptionCreate: React.FC<CreateProps> = (props) => {
  const { resourceId: organizationId } = useParams<{ resourceId: string }>();

  const { data: organizationDetails } = useGetOne<OrganizationDetails>(
    'organizations',
    organizationId,
  );
  const classes = useFormStyles();
  const currentDate = useMemo(() => new Date(), []);

  return (
    <Create
      resource="subscriptions"
      title={`Create Organization ${organizationId} Subscription`}
      actions={<TopToolbarWithBackButton />}
      {...props}
    >
      <SimpleForm
        redirect={redirect}
        component={FormContainer}
        toolbar={
          <SubscriptionCreateToolbar
            activeSubscriptionId={organizationDetails?.activeSubscriptionId}
          />
        }
      >
        <TranslatableInputs locales={AVAILABLE_LOCALES}>
          <TextInput
            label="Subscription Name"
            source="pricingPlan.contentI18n.name"
          />
        </TranslatableInputs>
        <TextInput
          disabled
          className={classes.formInput}
          source="organizationId"
          label="Organization ID"
          defaultValue={organizationId}
          validate={[required()]}
        />
        <DateTimeInput
          className={classes.formInput}
          source="startedAt"
          label="Started At"
          defaultValue={currentDate}
          validate={[required()]}
        />
        <DateTimeInput
          className={classes.formInput}
          source="expiresAt"
          label="Expires At"
          validate={[required()]}
        />
        <SelectInput
          className={classes.formInput}
          source="expiryBehavior"
          label="Expiry Behavior"
          choices={[
            { id: 'block_edit_create', name: 'Block Edit & Create' },
            { id: 'fallback_default', name: 'Fallback to Free Plan' },
          ]}
          defaultValue="block_edit_create"
          validate={[required()]}
        />
        <TextInput
          className={classes.formInput}
          label="PIC"
          source="personInCharge"
        />
        <TextInput
          className={classes.formInput}
          label="Contract ID/Invoice No."
          source="internalReferenceNo"
        />
        <TextInput
          className={classes.formInput}
          label="Internal remarks"
          source="note"
        />
        <IdentifierLabelField label="Organization Feature Config" />
        <OrganizationFeatureConfigInput
          hideOrgEventRegistrants
          source="pricingPlan.organizationFeatureConfig"
        />
        <IdentifierLabelField label="Feature Configs" />
        <FeatureConfigInput
          mode="pricing-plan"
          source="pricingPlan.defaultEventFeatureConfig"
        />
        <NumberInput
          key="maxNumberOfOrgEventRegistrants"
          fullWidth
          className={classes.formInput}
          label="Max Number of Total Event Registrants in Organization(deprecated)"
          source="pricingPlan.organizationFeatureConfig.maxNumberOfOrgEventRegistrants"
        />
        <TextInput
          className={classes.formInput}
          label="Internal remarks"
          source="pricingplan.note"
        />
      </SimpleForm>
    </Create>
  );
};
