import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { MonacoInput } from '../../components/common/MonacoInput';
import { TopToolbarWithBackButton } from '../../components/common/TopToolbarWithBackButton';
import { useFormStyles } from '../../hooks/useFormStyles';
import { injectedScriptTemplate } from '../../libs/injected-script';

export const EventInjectedScriptVersionCreate = (props: CreateProps) => {
  const classes = useFormStyles();

  return (
    <Create {...props} actions={<TopToolbarWithBackButton />}>
      <SimpleForm mutationMode="pessimistic">
        <TextInput
          disabled
          source="eventId"
          label="Event ID"
          className={classes.formInput}
          validate={[required()]}
        />
        <MonacoInput
          width="75vw"
          height="50vh"
          source="injectedScript"
          defaultValue={injectedScriptTemplate}
        />
      </SimpleForm>
    </Create>
  );
};
