import { TransformedReleaseControl } from '@/types';

export const checkTransformedReleaseControlCanRelease = (
  transformedReleaseControl?: TransformedReleaseControl,
) => {
  if (transformedReleaseControl === undefined) {
    return false;
  }

  if (transformedReleaseControl.isInitialized) {
    if (!transformedReleaseControl.releaseControl.isBackwardCompatible) {
      if (
        transformedReleaseControl.releaseControl.applyToEventCreatedAfter ===
          undefined ||
        transformedReleaseControl.releaseControl.applyToEventCreatedAfter ===
          null
      ) {
        return true;
      }
    }
  }

  return false;
};
