import { httpClient } from '../../App';

export const activateInjectedScriptVersion = async (versionId: string) => {
  const res = await httpClient(
    `${
      process.env.REACT_APP_API_BASE_URL ?? ''
    }/event-injected-script-versions/${versionId}/activate`,
    {
      method: 'POST',
    },
  );
  return {
    data: res.json as Record<string, unknown>,
  };
};

export const deactivateInjectedScriptVersion = async (versionId: string) => {
  const res = await httpClient(
    `${
      process.env.REACT_APP_API_BASE_URL ?? ''
    }/event-injected-script-versions/${versionId}/deactivate`,
    {
      method: 'POST',
    },
  );
  return {
    data: res.json as Record<string, unknown>,
  };
};
