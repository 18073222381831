import { TeamMemberCreate } from '@/components/common/TeamMemberCreate';
import { CreditSystemBalance } from '@/pages/credit-system/CreditSystemBalance';
import { EventEdit, EventFeatureConfigEdit } from '@/pages/event';
import {
  EventInternalInfoCreate,
  EventInternalInfoEdit,
  EventInternalInfoShow,
} from '@/pages/event-internal-info';
import { NameBadgeCustomTemplateCreate } from '@/pages/name-badge-custom-template/NameBadgeCustomTemplateCreate';
import { NameBadgeCustomTemplateEdit } from '@/pages/name-badge-custom-template/NameBadgeCustomTemplateEdit';
import { SubscriptionCreate, SubscriptionList } from '@/pages/subscription';
import { UserOrganizationCreate } from '@/pages/user/organizations/UserOrganizationCreate';
import { Route } from 'react-router-dom';
import { EventLeadFinderCopyLeads } from '../pages/event/EventLeadFinderCopyLeads';
import { ImportLeadFinderDemoUser } from '@/pages/user';
import { SystemConfigEdit, SystemConfigShow } from '@/pages/system';

const customRoutes = [
  <Route
    key="event-edit"
    exact
    path="/events/:eventId/edit"
    render={(props) => {
      return <EventEdit {...props} />;
    }}
  />,
  <Route
    key="event-feature-config-override"
    exact
    path="/events/:eventId/feature-config-override"
    render={(props) => {
      return <EventFeatureConfigEdit {...props} />;
    }}
  />,
  <Route
    key="event-user-invitation-create"
    exact
    path="/events/:resourceId/user-invitation/create"
    render={(props) => {
      return (
        <TeamMemberCreate
          resource="events"
          basePath="event-managers"
          redirect="show"
          source="eventId"
          title="Event"
          {...props}
        />
      );
    }}
  />,
  <Route
    key="event-internal-info-show"
    exact
    path="/events/:eventId/internal-info"
    render={(props) => {
      return <EventInternalInfoShow {...props} />;
    }}
  />,
  <Route
    key="event-internal-info-create"
    exact
    path="/events/:eventId/internal-info/create"
    render={(props) => {
      return <EventInternalInfoCreate {...props} />;
    }}
  />,
  <Route
    key="event-internal-info-edit"
    exact
    path="/events/:eventId/internal-info/edit"
    render={(props) => {
      return <EventInternalInfoEdit {...props} />;
    }}
  />,
  <Route
    key="name-badge-custom-template-create"
    exact
    path="/events/:eventId/name-badge-custom-template/create"
    render={(props) => {
      return <NameBadgeCustomTemplateCreate {...props} />;
    }}
  />,
  <Route
    key="name-badge-custom-template-edit"
    exact
    path="/events/:eventId/name-badge-custom-template/:templateId/edit"
    render={(props) => {
      return <NameBadgeCustomTemplateEdit {...props} />;
    }}
  />,
  <Route
    key="organization-user-invitation-create"
    exact
    path="/organizations/:resourceId/user-invitation/create"
    render={(props) => {
      return (
        <TeamMemberCreate
          resource="organizations"
          basePath="organization-managers"
          redirect="show"
          source="organizationId"
          title="Organization"
          {...props}
        />
      );
    }}
  />,
  <Route
    key="subscription-credit-system/balance"
    exact
    path="/subscriptions/:resourceId/credit-system/:creditType/balance"
    render={(props) => {
      return (
        <CreditSystemBalance resource="subscriptions" basePath=" " {...props} />
      );
    }}
  />,
  <Route
    key="organization-subscription-list"
    exact
    path="/organizations/:resourceId/subscriptions"
    render={(props) => {
      return (
        <SubscriptionList
          basePath="/organizations/:resourceId/subscriptions"
          redirect="show"
          source="organizationId"
          {...props}
        />
      );
    }}
  />,
  <Route
    key="organization-subscription-create"
    exact
    path="/organizations/:resourceId/subscriptions/create"
    render={(props) => {
      return (
        <SubscriptionCreate
          basePath="/organizations/:resourceId/subscriptions"
          {...props}
        />
      );
    }}
  />,
  <Route
    key="event-lead-finder-copy-leads"
    exact
    path="/events/:eventId/lead-finder/copy-leads"
    render={(props) => {
      return <EventLeadFinderCopyLeads {...props} />;
    }}
  />,
  <Route
    key="user-create-organization"
    exact
    path="/users/:userId/organization/create"
    render={(props) => {
      return <UserOrganizationCreate {...props} />;
    }}
  />,
  <Route
    key="import-lead-finder-demo-users"
    exact
    path="/users/import-lead-finder-demo-users"
    render={() => {
      return <ImportLeadFinderDemoUser />;
    }}
  />,
  <Route
    key="system-config"
    exact
    path="/system-config"
    render={(props) => {
      return (
        <SystemConfigShow
          {...props}
          title="System Config"
          resource="system-config"
          basePath="system-config"
          id=""
        />
      );
    }}
  />,
  <Route
    key="system-config-edit"
    exact
    path="/system-config/edit"
    render={(props) => {
      return (
        <SystemConfigEdit
          {...props}
          title="System Config"
          resource="system-config"
          basePath="system-config"
          id=""
        />
      );
    }}
  />,
];

export default customRoutes;
