import { memo } from 'react';
import {
  ResourceComponentProps,
  useRecordContext,
  linkToRecord,
  Button,
} from 'react-admin';
import { Link } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';

export const EventEditFeatureConfigButton = memo(
  (props: ResourceComponentProps) => {
    const record = useRecordContext(props);

    return record ? (
      <Link
        to={linkToRecord(`/events/${record.id}`, 'feature-config-override')}
        style={{ textDecoration: 'none' }}
      >
        <Button label="Override Event Feature Config (USE WITH CAUTION!)">
          <WarningIcon />
        </Button>
      </Link>
    ) : null;
  },
);
