import {
  Record,
  ResourceComponentProps,
  Datagrid,
  TextField,
  useRecordContext,
  ShowButton,
  FunctionField,
  BooleanField,
} from 'react-admin';
import { CreateImpersonateTokenButton } from './CreateImpersonateTokenButton';
import { EventListItem } from '@/types';
import { ImpersonateTokenWithAttendeeInfo } from '@/types/impersonate-token';

export const EventImpersonateTokenDatagrid: React.FC<ResourceComponentProps> = (
  props,
) => {
  const record = useRecordContext<EventListItem>(props);
  return (
    <>
      {record.createdByUserId ? (
        <CreateImpersonateTokenButton userId={record.createdByUserId} />
      ) : (
        <span>Can not create impersonate token as no created by userId</span>
      )}

      <Datagrid>
        <TextField sortable={false} source="id" label="Token Id" />
        <TextField
          source="attendeeInfo.invitationEmail"
          label="Attendee Email"
        />
        <TextField source="attendeeInfo.name" label="Attendee Name" />
        <FunctionField
          label="Attendee RoleTags"
          render={(record?: Record) => {
            const impersonateToken = record as
              | ImpersonateTokenWithAttendeeInfo
              | undefined;
            return (
              <span>
                {impersonateToken?.attendeeInfo?.roleTags?.join(', ')}
              </span>
            );
          }}
        />

        <TextField
          source="createdByInternalUserEmail"
          label="Created By Email"
        />
        <BooleanField key="isValid" fullWidth source="isValid" />
        <ShowButton basePath="/impersonate-tokens" />
      </Datagrid>
    </>
  );
};
