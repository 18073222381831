import { httpClient } from '@/App';
import { ComponentType, useCallback, useState } from 'react';
import {
  SimpleForm,
  required,
  FileInput,
  FileField,
  useNotify,
  Create,
  DataProvider,
  useDataProvider,
  Button,
} from 'react-admin';
import { downloadCsvFile } from '@/utils/file';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { Add } from '@material-ui/icons';

enum SubscriptionStatus {
  CREATED = 'created',
  EXTENDED = 'extended',
  EXISTING = 'existing',
}

interface ImportLeadFinderDemoUserResponse {
  invalidRecords: Array<{ email?: string }>;
  importedRecords: Array<{
    email: string;
    link: string;
    subscriptionStatus: SubscriptionStatus;
  }>;
}

interface CustomDataProvider extends DataProvider {
  importLeadFinderDemoUserFromCsv: (
    file: File,
  ) => Promise<{ data: ImportLeadFinderDemoUserResponse }>;
}

export const importLeadFinderDemoUserFromCsv = async (file: File) => {
  const headers = new Headers();
  const formData = new FormData();
  formData.set('file', file);

  const res = await httpClient(
    `${
      process.env.REACT_APP_API_BASE_URL ?? ''
    }/users/import-lead-finder-users`,
    {
      method: 'POST',
      body: formData,
      headers,
    },
  );
  return {
    data: res.json as ImportLeadFinderDemoUserResponse,
  };
};

export const ImportLeadFinderDemoUser: ComponentType = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const [importedResult, setImportedResult] = useState<{
    invalid: string[];
    extended: string[];
    existing: string[];
  }>();

  const handleOnSave = useCallback(
    async (values: { file: { rawFile: File; src: string; title: string } }) => {
      setImportedResult(undefined);
      try {
        const { data } = await dataProvider.importLeadFinderDemoUserFromCsv(
          values.file.rawFile,
        );
        const date = new Date();
        const formatDate = `${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

        setImportedResult({
          invalid: data.invalidRecords
            .map(({ email }) => email)
            .filter((email): email is string => Boolean(email)),
          extended: data.importedRecords
            .filter(
              (record) =>
                record.subscriptionStatus === SubscriptionStatus.EXTENDED,
            )
            .map((record) => record.email),
          existing: data.importedRecords
            .filter(
              (record) =>
                record.subscriptionStatus === SubscriptionStatus.EXISTING,
            )
            .map((record) => record.email),
        });

        if (data.importedRecords.length > 0) {
          downloadCsvFile(
            data.importedRecords.map(({ email, link }) => ({ email, link })),
            `imported-result-${formatDate}.csv`,
          );
          notify('Imported successfully, please check the downloaded CSV file');
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          notify(err.message);
        }
      }
    },
    [dataProvider, notify],
  );

  const handleDownloadTemplate = useCallback(() => {
    const records = [
      {
        email: 'example@mail.com',
      },
    ];
    downloadCsvFile(records, `Sample_User_List.csv`);
  }, []);

  return (
    <Create
      title="Import Lead Finder Users"
      resource="users/import-lead-finder-users"
      basePath="users/import-lead-finder-users"
      actions={<TopToolbarWithBackButton />}
    >
      <SimpleForm save={handleOnSave}>
        <Button
          style={{ width: 'unset' }}
          startIcon={<Add />}
          size="small"
          label="Download template"
          onClick={handleDownloadTemplate}
        />
        <Button />
        <FileInput
          fullWidth
          source="file"
          label="Upload emails file (.csv), Maximum: 1,000"
          accept=".csv"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
        {importedResult && (
          <div style={{ padding: '16px', width: '100%' }}>
            {importedResult.invalid.length > 0 && (
              <>
                <div style={{ color: 'red' }}>Invalid Emails:</div>
                <div>{importedResult.invalid.join(', ')}</div>
              </>
            )}
            {importedResult.extended.length > 0 && (
              <>
                <div style={{ color: 'red', marginTop: 8 }}>
                  Extended subscription:
                </div>
                <div>{importedResult.extended.join(', ')}</div>
              </>
            )}
            {importedResult.existing.length > 0 && (
              <>
                <div style={{ color: 'red', marginTop: 8 }}>
                  Skipped due to having active subscription:
                </div>
                <div>{importedResult.existing.join(', ')}</div>
              </>
            )}
          </div>
        )}
      </SimpleForm>
    </Create>
  );
};
