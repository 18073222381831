import { ComponentType } from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

export const CustomDomainEdit: ComponentType = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput fullWidth source="domain" validate={[required()]} />
        <TextInput
          fullWidth
          source="defaultRedirectUrl"
          validate={[required()]}
          defaultValue="https://eventx.io/"
        />
      </SimpleForm>
    </Edit>
  );
};
