export type CustomAutomatedEmailSenderType = Record<
  EmailCustomizableSenderType,
  string
> | null;

export enum EmailCustomizableSenderType {
  DEFAULT = 'default',
  ATTENDEE_INVITATION_EMAIL = 'attendee-invitation-email',
  ATTENDEE_RSVP_EMAIL = 'attendee-rsvp-email',
  CONFIRMATION_EMAIL = 'confirmation-email',
  CONFIRMATION_EMAIL_WITH_TICKET_SUMMARY = 'confirmation-email-with-ticket-summary',
  REMINDER_24H_EMAIL = 'reminder-24H-email',
  REMINDER_1H_EMAIL = 'reminder-1H-email',
  ORDER_SUMMARY_EMAIL = 'order-summary-email',
  ATTENDEE_APPROVAL_ACKNOWLEDGEMENT_EMAIL = 'attendee-approval-acknowledgement-email',
}
