import React, { memo } from 'react';
import {
  SimpleForm,
  NumberInput,
  EditProps,
  SaveButton,
  ToolbarProps,
  Toolbar,
  useGetOne,
  Edit,
  required,
  DateTimeInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { FormContainer } from '@/components/common/FormContainer';
import { useFormStyles } from '@/hooks/useFormStyles';

const EventInternalInfoEditToolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export const EventInternalInfoEdit: React.FC<EditProps> = memo(() => {
  const classes = useFormStyles();
  const { eventId } = useParams<{ eventId: string }>();
  const { data } = useGetOne('events', `${eventId}/internal-info`);
  const basePath = `/events/${eventId}/internal-info`;

  return (
    <Edit
      id={`${eventId}/internal-info`}
      title={`Edit Event Internal Info #${eventId}`}
      resource="events"
      basePath="events"
      actions={<TopToolbarWithBackButton path={basePath} />}
      mutationMode="pessimistic"
    >
      <SimpleForm
        undoable
        record={data}
        toolbar={<EventInternalInfoEditToolbar />}
        component={FormContainer}
        redirect={basePath}
      >
        <NumberInput
          source="estimatedAttendeeCount"
          className={classes.formInput}
          min={0}
          validate={[required()]}
        />
        <NumberInput
          source="estimatedHostAndSpeakerCount"
          className={classes.formInput}
          min={0}
        />
        <NumberInput
          source="estimatedWebinarSessionCount"
          className={classes.formInput}
          min={0}
        />
        <NumberInput
          source="estimatedProductCount"
          className={classes.formInput}
          min={0}
        />
        <NumberInput
          source="estimatedBoothCount"
          className={classes.formInput}
          min={0}
        />
        <NumberInput
          source="estimatedVideoLibraryItemCount"
          className={classes.formInput}
          min={0}
        />
        <DateTimeInput
          source="promotionStartsAt"
          className={classes.formInput}
        />
        <DateTimeInput source="liveStartsAt" className={classes.formInput} />
        <DateTimeInput source="liveEndsAt" className={classes.formInput} />
      </SimpleForm>
    </Edit>
  );
});
