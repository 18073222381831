// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-restricted-imports */

import typesSizzleIndex from '@types/sizzle/index.d.ts?raw';
import typesJqueryIndex from '@types/jquery/index.d.ts?raw';
import typesJqueryJQueryStatic from '@types/jquery/JQueryStatic.d.ts?raw';
import typesJqueryJQuery from '@types/jquery/JQuery.d.ts?raw';
import typesJqueryMisc from '@types/jquery/misc.d.ts?raw';
import typesJqueryLegacy from '@types/jquery/legacy.d.ts?raw';
import typesLodashIndex from '@types/lodash/index.d.ts?raw';
import typesLodashCommonCommon from '@types/lodash/common/common.d.ts?raw';
import typesLodashCommonArray from '@types/lodash/common/array.d.ts?raw';
import typesLodashCommonCollection from '@types/lodash/common/collection.d.ts?raw';
import typesLodashCommonDate from '@types/lodash/common/date.d.ts?raw';
import typesLodashCommonFunction from '@types/lodash/common/function.d.ts?raw';
import typesLodashCommonLang from '@types/lodash/common/lang.d.ts?raw';
import typesLodashCommonMath from '@types/lodash/common/math.d.ts?raw';
import typesLodashCommonNumber from '@types/lodash/common/number.d.ts?raw';
import typesLodashCommonObject from '@types/lodash/common/object.d.ts?raw';
import typesLodashCommonSeq from '@types/lodash/common/seq.d.ts?raw';
import typesLodashCommonString from '@types/lodash/common/string.d.ts?raw';
import typesLodashCommonUtil from '@types/lodash/common/util.d.ts?raw';

export const thirdPartyExtraLibs: Array<{ source: string; path: string }> = [
  {
    source: typesSizzleIndex,
    path: 'ts:node_modules/@types/sizzle/index.d.ts',
  },
  {
    source: typesJqueryIndex,
    path: 'ts:node_modules/@types/jquery/index.d.ts',
  },
  {
    source: typesJqueryJQueryStatic,
    path: 'ts:node_modules/@types/jquery/JQueryStatic.d.ts',
  },
  {
    source: typesJqueryJQuery,
    path: 'ts:node_modules/@types/jquery/JQuery.d.ts',
  },
  {
    source: typesJqueryMisc,
    path: 'ts:node_modules/@types/jquery/misc.d.ts',
  },
  {
    source: typesJqueryLegacy,
    path: 'ts:node_modules/@types/jquery/legacy.d.ts',
  },
  {
    source: typesLodashIndex,
    path: 'ts:node_modules/@types/lodash/index.d.ts',
  },
  {
    source: typesLodashCommonCommon,
    path: 'ts:node_modules/@types/lodash/common/common.d.ts',
  },
  {
    source: typesLodashCommonArray,
    path: 'ts:node_modules/@types/lodash/common/array.d.ts',
  },
  {
    source: typesLodashCommonCollection,
    path: 'ts:node_modules/@types/lodash/common/collection.d.ts',
  },
  {
    source: typesLodashCommonDate,
    path: 'ts:node_modules/@types/lodash/common/date.d.ts',
  },
  {
    source: typesLodashCommonFunction,
    path: 'ts:node_modules/@types/lodash/common/function.d.ts',
  },
  {
    source: typesLodashCommonLang,
    path: 'ts:node_modules/@types/lodash/common/lang.d.ts',
  },
  {
    source: typesLodashCommonMath,
    path: 'ts:node_modules/@types/lodash/common/math.d.ts',
  },
  {
    source: typesLodashCommonNumber,
    path: 'ts:node_modules/@types/lodash/common/number.d.ts',
  },
  {
    source: typesLodashCommonObject,
    path: 'ts:node_modules/@types/lodash/common/object.d.ts',
  },
  {
    source: typesLodashCommonSeq,
    path: 'ts:node_modules/@types/lodash/common/seq.d.ts',
  },
  {
    source: typesLodashCommonString,
    path: 'ts:node_modules/@types/lodash/common/string.d.ts',
  },
  {
    source: typesLodashCommonUtil,
    path: 'ts:node_modules/@types/lodash/common/util.d.ts',
  },
];
