export const injectedScriptTemplateLanguage = 'javascript';

export const injectedScriptTemplate = `/**
* Will be called when the spot site is loaded and every time the user navigates to a new page.
* @param {Object} param0
* @param {GetEventSettingsResponse} param0.eventSetting
* @param {UserSession | null} param0.userSession
* @param {GetCurrentAttendeeResponse | null} param0.currentAttendee
* @param {Location} param0.location
* @param {KyInstance} param0.ky
* @param {i18n} param0.i18n
*/
function onLoad({ eventSetting, userSession, currentAttendee, location, ky, i18n }) {
  // Please write your code here
}

/**
* Will be called when the spot site is unloaded and every time the user navigates to a new page.
* @param {Object} param0
* @param {GetEventSettingsResponse} param0.eventSetting
* @param {UserSession | null} param0.userSession
* @param {GetCurrentAttendeeResponse | null} param0.currentAttendee
* @param {Location} param0.location
* @param {KyInstance} param0.ky
* @param {i18n} param0.i18n
*/
function onUnload({ eventSetting, userSession, currentAttendee, location, ky, i18n }) {
  // Please write your code here
}

/**
* Will be called when React setState() is called, with 100ms debounce.
* @param {Object} param0
* @param {GetEventSettingsResponse} param0.eventSetting
* @param {UserSession | null} param0.userSession
* @param {GetCurrentAttendeeResponse | null} param0.currentAttendee
* @param {Location} param0.location
* @param {KyInstance} param0.ky
* @param {i18n} param0.i18n
*/
function onRender({ eventSetting, userSession, currentAttendee, location, ky, i18n }) {
  // Please write your code here
}

module.exports = {
  onLoad,
  onUnload,
  onRender,
};`;
