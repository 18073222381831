import { memo } from 'react';
import {
  ResourceComponentProps,
  useRecordContext,
  Datagrid,
  TextField,
  ReferenceField,
} from 'react-admin';
import { TeamMemberDeleteButton } from '@/components/common/buttons';

export const OrganizationManagerDatagrid: React.FC<ResourceComponentProps> =
  memo((props) => {
    const record = useRecordContext(props);

    return (
      <Datagrid>
        <ReferenceField
          label="Name"
          source="userId"
          reference="users"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Email"
          source="userId"
          reference="users"
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
        <TeamMemberDeleteButton
          resourceId={record.id as string}
          redirect={false}
        />
      </Datagrid>
    );
  });
