import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  Show,
  SimpleShowLayout,
  ShowProps,
  ReferenceManyField,
  TextField,
  SaveButton,
  SaveButtonProps,
  Toolbar,
  useQuery,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '../../components/common/TopToolbarWithBackButton';
import {
  CreditSystemBalance as BalanceType,
  CreditSystemMap,
  CreditSystemType,
  CreditSystemTransactionAction,
} from '@/types/credit-system';
import { SubscriptionCreditSystemTransactionDatagrid } from '@/components/credit-system/SubscriptionCreditSystemTransactionDatagrid';

const ButtonToolbar: React.FC<
  SaveButtonProps & {
    isCreateBalanceBefore: boolean;
  }
> = (props) => {
  const { isCreateBalanceBefore } = props;
  return (
    <Toolbar>
      <SaveButton
        {...props}
        label="TOP UP"
        style={{
          marginRight: '20px',
        }}
        transform={(record: Record<string, unknown>) => ({
          ...record,
          action: CreditSystemTransactionAction.TOP_UP,
        })}
      />
      {isCreateBalanceBefore && (
        <SaveButton
          {...props}
          label="DECREASE"
          transform={(record: Record<string, unknown>) => ({
            ...record,
            action: CreditSystemTransactionAction.DECREASE,
          })}
        />
      )}
    </Toolbar>
  );
};

const BalanceComponenet: React.FC<{
  balance: BalanceType;
}> = (props) => {
  const { balance } = props;

  return (
    <SimpleShowLayout
      record={{
        ...balance,
        remained: balance.totalCredits - balance.consumedCredits,
      }}
    >
      <TextField label="Total" source="totalCredits" />
      <TextField label="Comsumed" source="consumedCredits" />
      <TextField label="Remained" source="remained" />
    </SimpleShowLayout>
  );
};

export const CreditSystemBalance: React.FC<
  ShowProps & {
    resource: string;
  }
> = (props) => {
  const { resource, ...rest } = props;
  const { resourceId: subscriptionId, creditType } = useParams<{
    resourceId: string;
    creditType: CreditSystemType;
  }>();
  const redirectPath = `/${resource}/${subscriptionId}/show`;
  const creditTypeText = CreditSystemMap[creditType];
  const basePath = 'credit-system/balance/recharge';
  const [balance, setBalance] = React.useState<BalanceType>();
  useQuery(
    {
      type: 'getOne',
      resource: 'credit-system/balance',
      payload: {
        id: `${subscriptionId}?creditType=${creditType}`,
      },
    },
    {
      onSuccess({ data }: { data: BalanceType }) {
        setBalance(data);
      },
    },
  );

  return (
    <>
      {balance && (
        <Show
          id={subscriptionId}
          actions={<TopToolbarWithBackButton path={redirectPath} />}
          {...props}
          title={` ${creditTypeText}`}
        >
          <SimpleShowLayout>
            <BalanceComponenet balance={balance} />
            <ReferenceManyField
              reference="credit-system/transactions"
              target="subscriptionId"
              label={`${creditTypeText} Transaction History`}
              sort={{ field: 'createdAt', order: 'ASC' }}
              filter={{ creditType }}
            >
              <SubscriptionCreditSystemTransactionDatagrid />
            </ReferenceManyField>
          </SimpleShowLayout>
        </Show>
      )}
      <Create
        basePath={basePath}
        resource={basePath}
        {...rest}
        record={{
          subscriptionId,
          creditType,
        }}
        title="RECHARGE"
      >
        <SimpleForm
          toolbar={<ButtonToolbar isCreateBalanceBefore={Boolean(balance)} />}
          redirect={false}
        >
          <TextInput disabled source="subscriptionId" label="Subscription ID" />
          <TextInput disabled source="creditType" label="Credit Type" />
          <NumberInput required source="amount" min={1} />
          <TextInput source="notes" label="Notes" />
        </SimpleForm>
      </Create>
    </>
  );
};
