import React, { memo } from 'react';
import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  required,
  useCreate,
  Record,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { FormContainer } from '@/components/common/FormContainer';
import { useFormStyles } from '@/hooks/useFormStyles';

export const NameBadgeCustomTemplateCreate: React.FC<CreateProps> = memo(
  (props) => {
    const classes = useFormStyles();
    const { eventId } = useParams<{ eventId: string }>();
    const basePath = `events/${eventId}`;

    const [create] = useCreate();
    const postSave = (data: { name: string }) => {
      void create(`${basePath}/name-badge-custom-template`, {
        name: data.name,
      });
    };

    const tranformData = (data: Record) => ({
      id: data.id,
      name: data.name as string,
    });

    return (
      <Create
        title={`Create NameBadge Custom Template #${eventId}`}
        resource={`${basePath}/name-badge-custom-template`}
        basePath={`${basePath}/name-badge-custom-template`}
        actions={<TopToolbarWithBackButton path={`/${basePath}/show`} />}
        transform={tranformData}
        {...props}
      >
        <SimpleForm
          redirect={`/${basePath}/show`}
          component={FormContainer}
          onSubmit={postSave}
        >
          <TextInput
            disabled
            source="eventId"
            label="Event ID"
            defaultValue={eventId}
            className={classes.formInput}
            min={0}
            validate={[required()]}
          />
          <TextInput
            source="name"
            label="Custom Template Name"
            className={classes.formInput}
            min={0}
            validate={[required()]}
          />
        </SimpleForm>
      </Create>
    );
  },
);
