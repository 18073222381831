import { User } from '@/types';
import { ComponentType, useCallback } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  PasswordInput,
  email,
  minLength,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

export const UserCreate: ComponentType = (props) => {
  const history = useHistory();

  const onSuceess = useCallback(
    (result: { data: User }) => {
      const url = `/users/${result.data.id}/show`;
      history.push(url);
    },
    [history],
  );

  return (
    <Create onSuccess={onSuceess} {...props}>
      <SimpleForm>
        <TextInput fullWidth source="name" validate={[required()]} />
        <TextInput
          fullWidth
          source="email"
          type="email"
          validate={[required(), email()]}
        />
        <PasswordInput
          fullWidth
          source="password"
          type="password"
          validate={[
            required(),
            minLength(8, 'Password must be at least 8 characters'),
          ]}
        />
      </SimpleForm>
    </Create>
  );
};
