import { Button, useDelete, useNotify, useRefresh } from 'react-admin';
import { useCallback } from 'react';
import IconDelete from '@material-ui/icons/Delete';

interface DeletePublicApiTokenButtonProps {
  publicApiTokenId: string;
}

export const DeletePublicApiTokenButton: React.FC<
  DeletePublicApiTokenButtonProps
> = ({ publicApiTokenId }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [deleteOne] = useDelete(
    'public-api-tokens',
    `${publicApiTokenId}`,
    {},
    {
      mutationMode: 'pessimistic',
      onFailure: (_) => {
        notify('Fail to delete Public Api Token!', 'error');
      },
      onSuccess: (_) => {
        refresh();
        notify('Public Api Token deleted!');
      },
    },
  );

  const handleClick = useCallback(async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      'This will delete impersonate token, continue?',
    );

    if (!isConfirmed) {
      return;
    }

    await deleteOne();
  }, [deleteOne]);

  return (
    <Button
      style={{
        whiteSpace: 'nowrap',
      }}
      startIcon={<IconDelete />}
      label="Delete Public Api Token"
      onClick={handleClick}
    />
  );
};
