import { Link } from 'react-router-dom';
import IconAdd from '@material-ui/icons/Add';
import { Button, ResourceComponentProps, useRecordContext } from 'react-admin';

export const TeamMemberCreateButton = (
  props: ResourceComponentProps & {
    resource: string;
    title?: string;
  },
) => {
  const { resource, title, ...rest } = props;
  const record = useRecordContext(props);
  const fullPath = `/${resource}/${record.id}/user-invitation/create`;

  return (
    <Button
      component={Link}
      to={fullPath}
      label={title ?? 'Add Manager'}
      title={title ?? 'Add Manager'}
      {...rest}
    >
      <IconAdd />
    </Button>
  );
};
