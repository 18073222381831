import { Button, useDelete, useNotify, useRefresh } from 'react-admin';
import { useCallback } from 'react';
import IconDelete from '@material-ui/icons/Delete';

interface DeleteImpersonateTokenButtonProps {
  impersonateTokenId: string;
}

export const DeleteImpersonateTokenButton: React.FC<
  DeleteImpersonateTokenButtonProps
> = ({ impersonateTokenId }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [deleteOne] = useDelete(
    'impersonate-tokens',
    `${impersonateTokenId}`,
    {},
    {
      undoable: false,
      mutationMode: 'pessimistic',
      onFailure: (_) => {
        notify('Fail to delete Impersonate Token!', 'error');
      },
      onSuccess: (_) => {
        refresh();
        notify('Impersonate Token deleted!');
      },
    },
  );

  const handleClick = useCallback(async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      'This will delete impersonate token, continue?',
    );

    if (!isConfirmed) {
      return;
    }

    await deleteOne();
  }, [deleteOne]);

  return (
    <Button
      style={{
        whiteSpace: 'nowrap',
      }}
      startIcon={<IconDelete />}
      label="Delete Impersonate Token"
      onClick={handleClick}
    />
  );
};
