import { ComponentType } from 'react';
import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Record,
  TextField,
  TextInput,
} from 'react-admin';
import { DateTimeField } from '../../components/common/DateTimeField';
import { DefaultListPagination } from '../../components/common/DefaultListPagination';

export interface CustomDomainPathMappingRecord extends Record {
  pathName: string;
}

const filters = [
  <TextInput
    key="customDomainId"
    alwaysOn
    label="Custom Domain ID"
    source="customDomainId"
    placeholder="Must be a valid UUID"
  />,
];

export const CustomDomainPathMappingList: ComponentType = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    perPage={50}
    pagination={<DefaultListPagination />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={filters}
    exporter={false}
  >
    <Datagrid>
      <TextField sortable={false} source="id" label="Path ID" />
      <TextField sortable={false} source="eventId" label="Event ID" />
      <FunctionField<CustomDomainPathMappingRecord>
        sortable={false}
        label="Path"
        render={(record) => record?.pathName}
      />
      <DateTimeField hideLabel source="createdAt" />
      <EditButton />
    </Datagrid>
  </List>
);
