import { memo, useCallback, useState } from 'react';
import { Button, downloadCSV, useDataProvider } from 'react-admin';
import ImportExport from '@material-ui/icons/ImportExport';
import * as Sentry from '@sentry/react';
import convertor from 'json2csv';

interface StreamChatMessage {
  user: {
    name: string;
  };
  text: string;
  created_at: string;
}

interface ExportResultProp {
  url: string;
  messages: StreamChatMessage[];
}

type Props = {
  eventId: string;
  boothId: string;
};

export const EventBoothChatExportButton: React.FC<Props> = memo(
  ({ eventId, boothId }) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);

    const handleExportChat = useCallback(
      async (taskId: string | number) => {
        try {
          const response = await dataProvider.getOne(
            `stream-chat/export/${eventId}`,
            {
              id: taskId,
            },
          );

          const data = response.data as unknown as {
            result: ExportResultProp | null;
          };

          if (data.result) {
            setLoading(false);
            const { messages } = data.result;
            if (!messages.length) {
              // eslint-disable-next-line no-alert
              window.alert('No booth chat messages found');
              return;
            }

            const transformed = messages.map((message: StreamChatMessage) => ({
              name: message.user.name,
              text: message.text,
              createdAt: new Date(message.created_at).toLocaleString(),
            }));

            const csv = convertor.parse(transformed);
            downloadCSV(csv, 'export');
          } else {
            await handleExportChat(taskId);
          }
        } catch (err) {
          setLoading(false);
          // eslint-disable-next-line no-alert
          window.alert('Something wrong on exporting chat');
          Sentry.captureException(err);
        }
      },
      [dataProvider, eventId],
    );

    const handleExportRequest = useCallback(async () => {
      try {
        setLoading(true);
        const { data } = await dataProvider.create(
          `stream-chat/request-export/${eventId}/booth-chat/${boothId}`,
          { data: {} },
        );
        await handleExportChat(data.id);
      } catch (err) {
        setLoading(false);
        // eslint-disable-next-line no-alert
        window.alert('Something wrong on exporting chat');
        Sentry.captureException(err);
      }
    }, [boothId, dataProvider, eventId, handleExportChat]);

    return (
      <Button
        disabled={loading}
        label={loading ? 'Fetching export…' : 'Export Chatroom History'}
        onClick={handleExportRequest}
      >
        <ImportExport />
      </Button>
    );
  },
);
