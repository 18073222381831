import React from 'react';
import {
  ShowButton,
  ResourceComponentProps,
  TextInput,
  BooleanField,
} from 'react-admin';
import { Datagrid, List, TextField } from 'ra-ui-materialui';
import { DefaultListPagination } from '@/components/common/DefaultListPagination';
import { DateTimeField } from '@/components/common/DateTimeField';

const filters = [
  <TextInput
    key="id"
    alwaysOn
    label="ID"
    source="id"
    placeholder="Must be a valid UUID"
  />,

  <TextInput
    key="userId"
    alwaysOn
    label="UserID"
    source="userId"
    placeholder="Must be a valid UUID"
  />,
];

export const ImpersonateTokenList: React.ComponentType<
  ResourceComponentProps<Record<string, string>, any, any>
> = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    perPage={50}
    pagination={<DefaultListPagination />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={filters}
    exporter={false}
  >
    <Datagrid>
      <TextField sortable={false} source="id" label="Token Id" />
      <TextField source="user.email" label="User Email" />
      <TextField source="createdByInternalUserEmail" label="Created By Email" />
      <DateTimeField hideLabel source="createdAt" />
      <BooleanField key="isValid" fullWidth source="isValid" />
      <ShowButton />
    </Datagrid>
  </List>
);
