export enum CreditSystemType {
  OUTREACH_MANUAL_EMAIL = 'outreach-manual-email',
  LEAD_FINDER = 'lead-finder',
  ATTENDEE = 'attendee',
  AI = 'ai',
}

export const CreditSystemTypes = [
  CreditSystemType.ATTENDEE,
  CreditSystemType.LEAD_FINDER,
  CreditSystemType.OUTREACH_MANUAL_EMAIL,
  CreditSystemType.AI,
];

export const CreditSystemMap = {
  [CreditSystemType.OUTREACH_MANUAL_EMAIL]: 'Manual Email Credit',
  [CreditSystemType.LEAD_FINDER]: 'Lead Finder Credit',
  [CreditSystemType.ATTENDEE]: 'Attendee Credit',
  [CreditSystemType.AI]: 'AI Credit',
};

export interface CreditSystemBalance {
  consumedCredits: number;
  createdAt: string;
  creditType: CreditSystemType;
  id: string;
  netBalance: number;
  totalCredits: number;
  updatedAt: string;
}

export enum CreditSystemTransactionAction {
  DECREASE = 'decrease',
  TOP_UP = 'top-up',
}
