import { useCallback, useMemo } from 'react';
import { Identifier, useNotify, useRefresh, useUpdate } from 'react-admin';

export const useToggleReminderEmail = (params: {
  type: 'oneHour' | 'oneDay';
  record?: {
    id: Identifier;
    isReminderCampaignsInactive?: {
      oneHour: boolean;
      oneDay: boolean;
    };
  };
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading }] = useUpdate();

  const isReminderCampaignInactive = useMemo(
    () => params.record?.isReminderCampaignsInactive?.[params.type] ?? false,
    [params.record?.isReminderCampaignsInactive, params.type],
  );

  const action = useCallback(async () => {
    if (!params.record) {
      return;
    }

    const friendlyTypeName = params.type === 'oneDay' ? '24h' : '1h';

    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      `Are you sure you want to ${
        isReminderCampaignInactive ? 'enable' : 'disable'
      } ${friendlyTypeName} reminder email?`,
    );

    if (!isConfirmed) {
      return;
    }

    const isEnabled = isReminderCampaignInactive;
    await update(
      'events',
      `${params.record.id}/toggle-active-reminder-campaign`,
      { type: params.type, isEnabled },
    );

    if (!loading) {
      refresh();
      notify(
        `${
          isEnabled ? 'Enabled' : 'Disabled'
        } ${friendlyTypeName} reminder email`,
      );
    }
  }, [
    loading,
    notify,
    params.record,
    params.type,
    isReminderCampaignInactive,
    refresh,
    update,
  ]);

  return useMemo(
    () => ({ action, isActive: !isReminderCampaignInactive }),
    [action, isReminderCampaignInactive],
  );
};
