import React, { memo } from 'react';
import {
  Button,
  linkToRecord,
  Loading,
  NumberField,
  ShowProps,
  SimpleShowLayout,
  Title,
  useGetOne,
} from 'react-admin';
import { Card } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import IconEdit from '@material-ui/icons/Edit';
import { TopToolbarWithBackButton } from '@/components/common/TopToolbarWithBackButton';
import { DateTimeField } from '@/components/common/DateTimeField';
import { EventInternalInfoDeleteButton } from '@/components/event-internal-info/EventInternalInfoDeleteButton';

export const EventInternalInfoShow: React.FC<ShowProps> = memo(() => {
  const { eventId } = useParams<{ eventId: string }>();
  const { data } = useGetOne('events', `${eventId}/internal-info`);

  return data ? (
    <>
      <TopToolbarWithBackButton
        path={linkToRecord(`/events/${eventId}`, 'show')}
      />
      <Card>
        <Title title={`Event Internal Info #${eventId}`} />
        <SimpleShowLayout record={data}>
          <NumberField source="estimatedAttendeeCount" />
          <NumberField source="estimatedHostAndSpeakerCount" emptyText="-" />
          <NumberField source="estimatedWebinarSessionCount" emptyText="-" />
          <NumberField source="estimatedProductCount" emptyText="-" />
          <NumberField source="estimatedBoothCount" emptyText="-" />
          <NumberField source="estimatedVideoLibraryItemCount" emptyText="-" />
          <DateTimeField source="promotionStartsAt" />
          <DateTimeField source="liveStartsAt" />
          <DateTimeField source="liveEndsAt" />
          <Button
            component={Link}
            to={`/events/${eventId}/internal-info/edit`}
            label="Edit"
            title="Edit"
          >
            <IconEdit />
          </Button>
          <EventInternalInfoDeleteButton />
        </SimpleShowLayout>
      </Card>
    </>
  ) : (
    <Loading />
  );
});
