import {
  Button,
  ResourceComponentProps,
  useDelete,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useCallback } from 'react';
import IconDelete from '@material-ui/icons/Delete';

export const StreamChatTruncateButton = (
  props: ResourceComponentProps & {
    resource: string;
    label: string;
    channelType: 'public-chat' | 'booth-chat';
    channelId?: string;
  },
) => {
  const { resource, label, channelType, ...rest } = props;
  const record = useRecordContext(props);

  const [deleteOne] = useDelete(
    'stream-chat',
    `truncate/${record.id}/${channelType}`,
    {},
    {
      undoable: false,
      mutationMode: 'optimistic',
    },
  );
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = useCallback(async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      `${label}. This is very hard to undo this operation. Continue?`,
    );

    if (!isConfirmed) {
      return;
    }

    await deleteOne();

    refresh();
    notify('Stream chat data removed!');
  }, [label, deleteOne, notify, refresh]);

  return (
    <Button
      style={{
        whiteSpace: 'nowrap',
      }}
      startIcon={<IconDelete />}
      label={label}
      onClick={handleClick}
      {...rest}
    />
  );
};
