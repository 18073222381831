import React from 'react';
import {
  DateField,
  Button,
  ListButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  TopToolbar,
  BooleanField,
  TextField,
} from 'react-admin';
import { Link, useParams } from 'react-router-dom';
import IconChevronLeft from '@material-ui/icons/ChevronLeft';
import IconEdit from '@material-ui/icons/Edit';
import { ClipboardTextField } from '@/components/common/ClipboardTextField';
import { releaseStages } from '@/types';

const ReleaseControlShowActions: React.FC<ShowProps> = ({ basePath }) => {
  const { id } = useParams<{ id: string }>();

  return (
    <TopToolbar>
      <Button
        component={Link}
        to={`/release-controls/${id}`}
        label="Edit"
        title="Edit"
      >
        <IconEdit />
      </Button>
      <ListButton basePath={basePath} label="Back" icon={<IconChevronLeft />} />
    </TopToolbar>
  );
};

export const ReleaseControlShow: React.FC<ShowProps> = (props) => {
  const prefix = 'releaseControl';
  return (
    <Show actions={<ReleaseControlShowActions {...props} />} {...props}>
      <SimpleShowLayout>
        <ClipboardTextField label="ID" source="id" />
        {releaseStages.map((stage) => {
          return (
            <BooleanField
              key={stage}
              label={`Release Stage: ${stage}`}
              source={`${prefix}.${stage}`}
              sortable={false}
            />
          );
        })}
        <BooleanField
          label="Is Backward Compatible"
          source={`${prefix}.isBackwardCompatible`}
          sortable={false}
        />
        <DateField
          source={`${prefix}.applyToEventCreatedAfter`}
          label="Apply To Event Created After"
          sortable={false}
        />
        <DateField
          source={`${prefix}.createdAt`}
          label="Created At"
          sortable={false}
        />
        <DateField
          source={`${prefix}.updatedAt`}
          label="Updated At"
          sortable={false}
        />
        <TextField
          label="Internal remarks"
          source={`${prefix}.note`}
          emptyText="-"
        />
      </SimpleShowLayout>
    </Show>
  );
};
