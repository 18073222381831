import React from 'react';
import { Create, SimpleForm, CreateProps, TextInput } from 'react-admin';
import { useParams } from 'react-router-dom';
import { TopToolbarWithBackButton } from './TopToolbarWithBackButton';

export const TeamMemberCreate: React.FC<
  CreateProps & {
    resource: string;
    basePath: string;
    redirect: string;
    source: string;
    title: string;
  }
> = (props) => {
  const { resource, basePath, redirect, source, title, ...rest } = props;
  const { resourceId } = useParams<{ resourceId: string }>();
  const redirectPath = `/${resource}/${resourceId}/${redirect}`;

  return (
    <Create
      basePath={basePath}
      resource={basePath}
      actions={<TopToolbarWithBackButton path={redirectPath} />}
      title={`Create ${title} Manager`}
      {...rest}
    >
      <SimpleForm redirect={redirectPath}>
        <TextInput
          disabled
          source={source}
          label={`${title} ID`}
          defaultValue={resourceId}
        />
        <TextInput source="invitationEmail" type="email" />
      </SimpleForm>
    </Create>
  );
};
