import { TextInput, TextInputProps, regex } from 'react-admin';

export const CustomDomainPathNameTextInput = (props: TextInputProps) => (
  <TextInput
    {...props}
    validate={[
      regex(
        /^(?!demo\/?$|events(\/|$)|e(\/|$)).*/i,
        'Must not be events, e, demo',
      ),
    ]}
  />
);
