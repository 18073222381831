import { Button, SelectInput, useQuery } from 'react-admin';
import { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useApplyPricingPlanFeatures } from '@/hooks/event/useApplyPricingPlanFeatures';
import IconFileCopy from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginBottom: '-15px',
  },
  buttonContainer: {
    marginTop: '-15px',
    marginLeft: '5px',
  },
}));

interface PricingPlan {
  id: string;
  note: string;
}

interface PricingPlanButtonProps {
  eventId: string;
  pricingPlanId: string;
}

const PricingPlanButton: React.FC<PricingPlanButtonProps> = (props) => {
  const handleApplyPricingPlanFeatures = useApplyPricingPlanFeatures({
    eventId: props.eventId,
    pricingPlanId: props.pricingPlanId,
  });

  return (
    <Button
      key={props.pricingPlanId}
      startIcon={<IconFileCopy />}
      label="Override with pricing plan's features"
      onClick={handleApplyPricingPlanFeatures}
    />
  );
};

interface ApplyPricingPlanProps {
  eventId: string;
}

export const ApplyPricingPlan: React.FC<ApplyPricingPlanProps> = (props) => {
  const classes = useStyles();
  const [selectedPricingPlan, setSelectedPricingPlan] = useState<string>('');
  const { data, loading } = useQuery({
    type: 'getList',
    resource: 'pricing-plans',
    payload: {
      pagination: { page: 1, perPage: 25 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    },
  }) as { data: PricingPlan[] | undefined; loading: boolean };

  const handlePricingPlanSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedPricingPlan(e.target.value);
    },
    [],
  );

  const pricingPlans = useMemo(
    () =>
      data?.map((item) => ({
        id: item.id,
        name: `${item.id}${item.note ? ` (${item.note})` : ''}`,
      })),
    [data],
  );

  if (loading) return <CircularProgress size="1.5rem" />;

  return (
    <div className={classes.container}>
      <SelectInput
        source="pricingPlan"
        choices={pricingPlans}
        onChange={handlePricingPlanSelect}
      />
      <div className={classes.buttonContainer}>
        {selectedPricingPlan !== '' && (
          <PricingPlanButton
            key={selectedPricingPlan}
            eventId={props.eventId}
            pricingPlanId={selectedPricingPlan}
          />
        )}
      </div>
    </div>
  );
};
