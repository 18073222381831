import { useMemo } from 'react';
import { BooleanInput, NumberInput, TextInput } from 'react-admin';

import { OrganizationFeatureConfig } from '@/types';
import { useFormStyles } from '@/hooks/useFormStyles';

interface Props {
  source: string;
  isDisabled?: boolean;
  hideOrgEventRegistrants?: boolean;
}

export const OrganizationFeatureConfigInput: React.FC<Props> = ({
  source,
  isDisabled,
  hideOrgEventRegistrants,
}) => {
  const organizationFeatureConfigComponents = useMemo<
    Record<keyof OrganizationFeatureConfig, JSX.Element | false>
  >(
    () => ({
      isAllAttendeesLeadCaptureLicensedEnabled: (
        <BooleanInput
          key="isAllAttendeesLeadCaptureLicensedEnabled"
          fullWidth
          disabled={isDisabled}
          label="Allow All Attendees Of Organization To Use Lead Capture"
          source={`${source}.isAllAttendeesLeadCaptureLicensedEnabled`}
        />
      ),
      isHandheldScannerSupportingEnabled: (
        <BooleanInput
          key="isHandheldScannerSupportingEnabled"
          fullWidth
          disabled={isDisabled}
          label="Enable Handheld Scanner in Check-in App & Lead Capture"
          source={`${source}.isHandheldScannerSupportingEnabled`}
        />
      ),
      isStripeAlipayEnabled: (
        <BooleanInput
          key="isStripeAlipayEnabled"
          fullWidth
          disabled={isDisabled}
          label="Enable Stripe Alipay"
          source={`${source}.isStripeAlipayEnabled`}
        />
      ),
      isSalesforceIntegrationEnabled: (
        <BooleanInput
          key="isSalesforceIntegrationEnabled"
          fullWidth
          disabled={isDisabled}
          label="Enable Salesforce Integration"
          source={`${source}.isSalesforceIntegrationEnabled`}
        />
      ),
      maxNumberOfOrganizationManagers: (
        <NumberInput
          key="maxNumberOfOrganizationManagers"
          fullWidth
          disabled={isDisabled}
          label="Max Number of Organization Managers"
          source={`${source}.maxNumberOfOrganizationManagers`}
        />
      ),
      maxNumberOfOrgEventRegistrants: !hideOrgEventRegistrants && (
        <NumberInput
          key="maxNumberOfOrgEventRegistrants"
          fullWidth
          disabled={isDisabled}
          label="Max Number of Total Event Registrants in Organization(deprecated)"
          source={`${source}.maxNumberOfOrgEventRegistrants`}
        />
      ),
      maxNumberOfOrgBooths: (
        <NumberInput
          key="maxNumberOfOrgBooths"
          fullWidth
          disabled={isDisabled}
          label="Max Number of Total Booths in Organization"
          source={`${source}.maxNumberOfOrgBooths`}
        />
      ),
      isInviteAttendeeEnabled: (
        <BooleanInput
          fullWidth
          disabled={isDisabled}
          source={`${source}.isInviteAttendeeEnabled`}
          fieldName="isInviteAttendeeEnabled"
          label="Enable Invite Attendee Button"
        />
      ),
      isWebinarToolZoomIntegrationEnabled: (
        <BooleanInput
          fullWidth
          disabled={isDisabled}
          source={`${source}.isWebinarToolZoomIntegrationEnabled`}
          fieldName="isWebinarToolZoomIntegrationEnabled"
          label="Enable Zoom Integration on Webinar Tool selection"
        />
      ),
      displayTenantDbHostRegion: (
        <TextInput
          fullWidth
          source={`${source}.displayTenantDbHostRegion`}
          fieldName="displayTenantDbHostRegion"
          label="Tenant DB Host Region(Only For Display)"
        />
      ),
      displayTenantDbHostCluster: (
        <TextInput
          fullWidth
          source={`${source}.displayTenantDbHostCluster`}
          fieldName="displayTenantDbHostCluster"
          label="Tenant DB Host Cluster(Only For Display)"
        />
      ),
    }),
    [source, isDisabled, hideOrgEventRegistrants],
  );

  const classes = useFormStyles();

  return (
    <div className={classes.formInput}>
      {Object.values(organizationFeatureConfigComponents).map(
        (component) => component,
      )}
    </div>
  );
};
