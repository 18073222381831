import React, { useCallback } from 'react';
import { Record as AdminRecord, TextFieldProps } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';

import Editor from '@monaco-editor/react';
import {
  NameBadgeTemplateLayoutConfig,
  NameBadgePreview,
} from './NameBadgePreview';

interface NameBadgeTemplateEditorProps extends Omit<TextFieldProps, 'source'> {
  source: string;
  record?: AdminRecord | undefined;
}

const NameBadgeTemplateEditor: React.FC<NameBadgeTemplateEditorProps> = ({
  source,
  record,
}) => {
  const form = useForm();
  const { values } = useFormState();
  const layoutConfig = values?.layoutConfig as NameBadgeTemplateLayoutConfig;

  const handleJSONChange = useCallback(
    (jsonData: string | undefined): void => {
      form.change(source, JSON.parse(jsonData ?? '{}'));
    },
    [form, source],
  );

  const value = (record?.[source] as NameBadgeTemplateLayoutConfig) || {};

  const formatJSON = (val: NameBadgeTemplateLayoutConfig) => {
    try {
      return JSON.stringify(val, null, 2);
    } catch {
      const errorJson = {
        error: {
          msg: 'Error',
          ...val,
        },
      };
      return JSON.stringify(errorJson, null, 2);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: 1000,
        minHeight: 500,
        height: layoutConfig.height,
      }}
    >
      <Editor
        language="json"
        width="550px"
        theme="vs-dark"
        value={formatJSON(value)}
        options={{
          selectOnLineNumbers: true,
          formatOnPaste: true,
          automaticLayout: true,
        }}
        onChange={handleJSONChange}
      />

      <NameBadgePreview layoutConfig={layoutConfig} />
    </Box>
  );
};

export default NameBadgeTemplateEditor;
