import React, { Fragment } from 'react';
import {
  Layout as DefaultLayout,
  LayoutProps,
  Sidebar as DefaultSidebar,
  AppBar as DefaultAppBar,
  AppBarProps,
} from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';
import { Menu } from './Menu';

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: '#B3322B',
    '& a': {
      color: '#fff',
    },
    '& .MuiListItemIcon-root': {
      color: 'rgba(255, 255, 255, 0.8)',
    },
  },
  fixed: {
    backgroundColor: '#B3322B',
  },
});

const Sidebar: React.ComponentType<any> = (props) => {
  const classes = useSidebarStyles();
  return <DefaultSidebar classes={classes} {...props} />;
};

const useAppBarStyles = makeStyles({
  toolbar: {
    backgroundColor: '#111',
  },
  title: {
    color: '#fff',
    marginRight: 'auto',
  },
});

const AppBar: React.FC<AppBarProps> = (props) => {
  const classes = useAppBarStyles();
  return (
    <DefaultAppBar classes={classes} container={Fragment} {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </DefaultAppBar>
  );
};

const useRootStyles = makeStyles({
  root: {
    '& #main-content': {
      marginTop: '10px',
    },
  },
});

export const Layout: React.FC<LayoutProps> = (props) => {
  const classes = useRootStyles();
  return (
    <DefaultLayout
      {...props}
      className={classes.root}
      sidebar={Sidebar}
      appBar={AppBar}
      menu={Menu}
    />
  );
};
