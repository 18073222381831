import { FeatureFlagKey } from './feature-config';

// New Feature Release Control types
export enum ReleaseStage {
  DEV = 'dev',
  INTERNAL = 'internal',
  ALPHA = 'alpha',
  BETA = 'beta',
  PREVIEW = 'preview',
  PUBLIC = 'public',
}
export const releaseStages = Object.values(ReleaseStage);
export type ReleaseControl = {
  id: FeatureFlagKey;
  [ReleaseStage.DEV]: boolean;
  [ReleaseStage.INTERNAL]: boolean;
  [ReleaseStage.ALPHA]: boolean;
  [ReleaseStage.BETA]: boolean;
  [ReleaseStage.PREVIEW]: boolean;
  [ReleaseStage.PUBLIC]: boolean;
  isBackwardCompatible: boolean;
  createdAt: Date;
  updatedAt: Date;
  applyToEventCreatedAfter: Date | null;
  note: string | null;
};
export type TransformedReleaseControl = {
  id: FeatureFlagKey;
  releaseControl: ReleaseControl;
  isInitialized: boolean;
};
